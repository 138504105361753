import * as React from 'react';
import { GlobalFilterState } from '../GlobalFilterState';
import { RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs';

interface DateTimeFieldPickerProps {
  dateFiltersState: GlobalFilterState[];
  fieldSelected: GlobalFilterState;
  handleFieldChange: (event: RadioButtonChangeEvent) => void;
}

export const DateTimeFieldPicker: React.FC<DateTimeFieldPickerProps> = props => {
  return (
    <ul className="dateTimeFieldPicker" contentEditable={true} suppressContentEditableWarning={true}>
      {props.dateFiltersState.map(filter => (
        <li key={filter.id} contentEditable={false}>
          <RadioButton
            name="group1"
            value={filter.id}
            checked={props.fieldSelected?.id === filter.id}
            label={filter.displayName}
            onChange={props.handleFieldChange}
          />
        </li>
      ))}
    </ul>
  );
};
