import * as React from 'react';
import { FieldsContext } from './FieldsContext';
import { useGetAllFields } from './useGetAllFields';
import { useGlobalizationContext } from '../globalization/GlobalizationContext';
import { useCurrentUserContext } from '../currentUser/CurrentUserContext';

interface FieldsProviderProps {
  children?: React.ReactNode;
}

export const FieldsProvider: React.FC<FieldsProviderProps> = ({ children }) => {
  const { selectedLocale } = useGlobalizationContext();
  const { selectedIndex } = useCurrentUserContext();
  const { data } = useGetAllFields(selectedLocale, selectedIndex?.id);

  return (
    <FieldsContext.Provider
      value={{
        data
      }}
    >
      {children}
    </FieldsContext.Provider>
  );
};
