import * as React from 'react';
import { Typography } from '@progress/kendo-react-common';
import GearPage from './GearPage';
import { useLocalization } from '@progress/kendo-react-intl';
import { IntlHelper } from '../helpers/IntlHelper';
import { SPO_MANUALS_URL } from '../config/consts';

const NotExistingPage: React.FC = () => {
  const localization = useLocalization();
  return (
    <GearPage className="gearPage--accessDenied">
      <Typography.h2>{IntlHelper.toLangStr(localization, 'custom.accessDeniedPage.title')}</Typography.h2>
      <Typography.p>{IntlHelper.toLangStr(localization, 'custom.accessDeniedPage.description')}</Typography.p>
      <a href={`${SPO_MANUALS_URL}/GEAR access granting 1.0.pdf`} target="_blank" rel="noreferrer">
        GEAR access granting
      </a>
    </GearPage>
  );
};

export default NotExistingPage;
