import * as React from 'react';
import { CurrentUserContext } from './CurrentUserContext';
import { useGetCurrentUser } from './useGetCurrentUser';
import { GearIndex } from '../../Model/GearIndex';
import { USER_INDEX_SETTINGS_KEY } from '../../config/consts';

interface CurrentUserProviderProps {
  children?: React.ReactNode;
}

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({ children }) => {
  const { data: currentUser, isLoading } = useGetCurrentUser();
  const [selectedIndex, setSelectedIndex] = React.useState<GearIndex>();

  const setIndexSettingsKey = (indexId: number) => {
    window.localStorage.setItem(USER_INDEX_SETTINGS_KEY, indexId.toString());
  };

  const getIndexSettingsKey = () => {
    return window.localStorage.getItem(USER_INDEX_SETTINGS_KEY);
  };

  React.useEffect(() => {
    if (currentUser && currentUser.indexes.length > 0) {
      const storedIndexId = getIndexSettingsKey();
      if (storedIndexId) {
        const foundStoredIndex = currentUser.indexes.find(index => index.id === parseInt(storedIndexId));
        if (foundStoredIndex) {
          setSelectedIndex(foundStoredIndex);
        }
      } else {
        setSelectedIndex(currentUser.indexes[0]);
      }
    }
  }, [currentUser]);

  const handleSetSelectedIndex = (index: GearIndex) => {
    setSelectedIndex(index);
    setIndexSettingsKey(index.id);
  };

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        isLoading,
        selectedIndex,
        setSelectedIndex: handleSetSelectedIndex
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
