import { useLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { useCurrentUserContext } from '../../../context/currentUser/CurrentUserContext';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GearIndex } from '../../../Model/GearIndex';
import { IntlHelper } from '../../../helpers/IntlHelper';

const IndexDropdown: React.FC = () => {
  const { currentUser, selectedIndex, setSelectedIndex } = useCurrentUserContext();
  const localization = useLocalization();
  return (
    <>
      {currentUser?.indexes && currentUser.indexes.length > 1 && (
        <DropDownList
          className="indexDropdown"
          title={selectedIndex?.displayName}
          label={IntlHelper.toLangStr(localization, 'custom.toolbar.index')}
          data={currentUser.indexes}
          textField={'displayName'}
          dataItemKey="id"
          value={selectedIndex}
          onChange={(event: DropDownListChangeEvent) => {
            if (setSelectedIndex && event.value) {
              setSelectedIndex(event.value as GearIndex);
            }
          }}
        />
      )}
    </>
  );
};

export default IndexDropdown;
