import {
  Grid,
  GridCellProps,
  GridColumn,
  GridFilterChangeEvent,
  GridHeaderCellProps,
  GridHeaderSelectionChangeEvent,
  GridNoRecords,
  GridPageChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import HiddenHeader from './HiddenHeader';
import LinkedCell from './customCells/LinkedCell';
import DateCell from './customCells/DateCell';
import { CompositeFilterDescriptor, FilterDescriptor, getter, SortDescriptor } from '@progress/kendo-data-query';
import {
  CONTENT_TYPE_FIELD,
  DEFAULT_ROWS_PER_PAGE,
  DOC_ID_FIELD,
  EXTENSION_FIELD,
  GRID_PAGE_SIZES,
  NOTE_FIELD,
  SELECTED_FIELD
} from '../../config/consts';
import GearLoader from '../common/GearLoader';
import { ColumnMenu } from './columnMenu/ColumnMenu';
import { GEAR_FIELD_TYPE } from '../../global/enums/GearFieldType';
import { ElementDimensions, useWindowDimensions } from '../../global/hooks/useWindowDimensions';
import { GearFacetValue } from '../../Model/GearFacets';
import { ExtensionCell } from './customCells/ExtensionCell';
import { useViewsContext } from '../../context/view/ViewsContext';
import { useSearchContext } from '../../context/search/SearchContext';
import { GearSearchQueryRequest } from '../../Model/GearSearchQueryRequest';
import { GearDocument } from '../../Model/GearDocument';
import * as _ from 'lodash';
import { GearToolbar } from './toolbar/GearToolbar';
import { GearFilterRequest } from '../../Model/GearFilterRequest';
import { HeaderCell } from './customCells/HeaderCell';
import { useDocumentsContext } from '../../context/documents/DocumentsContext';
import { GearSelectedDocument } from '../../Model/GearSelectedDocument';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { EditableNumericTextBoxCell } from './customCells/EditableNumericTextBoxCell';
import { GearGridCell } from './customCells/GearGridCell';
import { getGearDateFilterFormat } from '../../helpers/DayJsHelper';
import DateTimeCell from './customCells/DateTimeCell';
import { useCurrentUserContext } from '../../context/currentUser/CurrentUserContext';

const idGetter = getter(DOC_ID_FIELD);
const contentTypeGetter = getter(CONTENT_TYPE_FIELD);

type GearGridCommonProperties = {
  [DOC_ID_FIELD]: string;
  [CONTENT_TYPE_FIELD]: string;
  [EXTENSION_FIELD]: string;
  [SELECTED_FIELD]?: boolean;
  [NOTE_FIELD]?: boolean;
};
export type GearGridDocument = GearGridCommonProperties & GearDocument;

const GearGrid: React.FC = () => {
  const localization = useLocalization();
  const ref = useRef<HTMLDivElement>(null);
  const { data, isLoading, queryRequest, setQueryRequest, compositeFilterDescriptor, setCompositeFilterDescriptor } =
    useSearchContext();
  const { selectedDocuments, setSelectedDocuments, openDocument, openDocumentLoading, downlaodFilesLoading } =
    useDocumentsContext();
  const { selectedIndex } = useCurrentUserContext();
  const [gridData, setGridData] = useState<GearGridDocument[]>([]);
  const { defaultUserView: defaultView, defaultUserViewLoading: defaultViewLoading } = useViewsContext();
  const columns = defaultView?.fields;

  useEffect(() => {
    if (!columns || !setQueryRequest || !queryRequest) {
      return;
    }

    const viewFacets = columns.filter(field => field.facetable).map(field => field.name);
    const selectColumns = columns
      .map(column => column.name)
      .concat([DOC_ID_FIELD, CONTENT_TYPE_FIELD, EXTENSION_FIELD])
      .filter((value, index, array) => array.indexOf(value) === index);

    if (
      !_.isEqual(viewFacets, queryRequest.facets) ||
      !_.isEqual(selectColumns, queryRequest.select) ||
      !queryRequest.facets
    ) {
      setQueryRequest({
        ...queryRequest,
        facets: viewFacets,
        select: selectColumns,
        indexId: selectedIndex?.id
      });
    }
  }, [columns, queryRequest, setQueryRequest, selectedIndex]);

  React.useEffect(() => {
    if (!data?.documents) {
      return;
    }
    const result: GearGridDocument[] = data.documents.map(item => ({
      [DOC_ID_FIELD]: item[DOC_ID_FIELD] as string,
      [CONTENT_TYPE_FIELD]: item[CONTENT_TYPE_FIELD] as string,
      [EXTENSION_FIELD]: item[EXTENSION_FIELD] as string,
      ...item,
      [SELECTED_FIELD]: selectedDocuments.find(doc => doc[DOC_ID_FIELD] === idGetter(item)) ? true : false
    }));
    setGridData(result);
    if (setSelectedDocuments) {
      setSelectedDocuments([]);
    }
  }, [data?.documents]);

  const sort: SortDescriptor[] | undefined = useMemo(() => {
    if (!queryRequest?.orderBy) {
      return undefined;
    }
    return [
      {
        field: queryRequest.orderBy,
        dir: queryRequest.orderByIsDescending ? 'desc' : 'asc'
      }
    ];
  }, [queryRequest?.orderBy, queryRequest?.orderByIsDescending]);

  const sortChange = (descriptors: SortDescriptor[]) => {
    if (queryRequest && setQueryRequest) {
      const newQueryParams: GearSearchQueryRequest = {
        ...queryRequest,
        orderBy: descriptors.length > 0 ? descriptors[0].field : undefined,
        orderByIsDescending: descriptors.length > 0 && descriptors[0].dir === 'desc' ? true : undefined
      };
      setQueryRequest(newQueryParams);
    }
  };

  useEffect(() => {
    if (!setQueryRequest || !queryRequest || !columns) {
      return;
    }
    const globalFilters = queryRequest.filters ? [...queryRequest.filters.filter(filter => filter.isGlobal)] : [];
    const mappedGridFilters = compositeFilterDescriptor?.filters
      .filter(descriptor => (descriptor as CompositeFilterDescriptor).filters.length > 0)
      .map(descriptor => {
        const newFiltersDescriptors = (descriptor as CompositeFilterDescriptor).filters as FilterDescriptor[];
        const filteredFieldName = newFiltersDescriptors[0].field;
        const filteredOperator = newFiltersDescriptors[0].operator;
        const filteredFieldType = columns.find(field => field.name === filteredFieldName)?.type || GEAR_FIELD_TYPE.TEXT;
        const filteredValues = newFiltersDescriptors
          .filter(i => i.value !== undefined && i.value !== null)
          .map(i => {
            if (filteredFieldType === GEAR_FIELD_TYPE.DATE || filteredFieldType === GEAR_FIELD_TYPE.DATETIME) {
              return getGearDateFilterFormat(i.value);
            }
            return i.value?.toString();
          });
        const newFilter = {
          fieldName: filteredFieldName,
          operator: filteredOperator,
          values: filteredValues,
          fieldType: filteredFieldType === GEAR_FIELD_TYPE.DATETIME ? GEAR_FIELD_TYPE.DATE : filteredFieldType
        } as GearFilterRequest;

        return newFilter;
      });
    // We don't want to let grid filters to overwrite global filters
    const newFilters = globalFilters.concat(mappedGridFilters || []);
    setQueryRequest({
      ...queryRequest,
      filters: newFilters,
      skip: 0
    });
  }, [compositeFilterDescriptor]);

  const headerRender = (tdElement: React.ReactNode, headerProps: GridHeaderCellProps) => {
    if (headerProps.field === SELECTED_FIELD) {
      return tdElement;
    }
    return (
      <HeaderCell
        compositeFilterDescriptor={compositeFilterDescriptor}
        headerProps={headerProps}
        tdElement={tdElement}
      />
    );
  };
  const cellRender = React.useCallback(
    (tdElement: React.ReactElement<HTMLTableCellElement> | null, cellProps: GridCellProps) => {
      if (!cellProps.field || cellProps.field === SELECTED_FIELD) {
        return tdElement;
      }
      if (cellProps.field === EXTENSION_FIELD) {
        return <ExtensionCell {...cellProps} />;
      } else if (
        columns
          ?.filter(field => field.type === GEAR_FIELD_TYPE.DATE)
          .map(field => field.name)
          .includes(cellProps.field)
      ) {
        return <DateCell {...cellProps} />;
      } else if (
        columns
          ?.filter(field => field.type === GEAR_FIELD_TYPE.DATETIME)
          .map(field => field.name)
          .includes(cellProps.field)
      ) {
        return <DateTimeCell {...cellProps} />;
      } else if (
        columns
          ?.filter(field => field.isDocumentLink)
          .map(field => field.name)
          .includes(cellProps.field)
      ) {
        return (
          <LinkedCell
            {...cellProps}
            onLinkClicked={() => {
              if (openDocument) {
                const docId = cellProps.dataItem[DOC_ID_FIELD];
                const contentType = cellProps.dataItem[CONTENT_TYPE_FIELD];
                openDocument(contentType, docId);
              }
            }}
          />
        );
      } else if (
        columns
          ?.filter(field => field.type === GEAR_FIELD_TYPE.NUMERIC)
          .map(field => field.name)
          .includes(cellProps.field)
      ) {
        return <EditableNumericTextBoxCell {...cellProps} inEdit={false} />;
      }
      return (
        <GearGridCell {...cellProps} title={cellProps.dataItem[cellProps.field] || ''}>
          {cellProps.dataItem[cellProps.field] || ''}
        </GearGridCell>
      );
    },
    [columns]
  );

  const onSelectionChange = React.useCallback(
    (event?: GridSelectionChangeEvent) => {
      if (!setSelectedDocuments) {
        return;
      }
      if (!event) {
        // reset selection
        setSelectedDocuments([]);
        return;
      }
      if (!event.dataItem) {
        return;
      }
      const selectedItem: GearGridDocument = event.dataItem;
      const selectedDocument: GearSelectedDocument = {
        [DOC_ID_FIELD]: selectedItem[DOC_ID_FIELD],
        [CONTENT_TYPE_FIELD]: selectedItem[CONTENT_TYPE_FIELD]
      };
      let newSelectedDocuments = [...selectedDocuments];
      if (selectedDocuments.find(selected => selected[DOC_ID_FIELD] === selectedDocument[DOC_ID_FIELD])) {
        newSelectedDocuments = newSelectedDocuments.filter(
          selected => selected[DOC_ID_FIELD] !== selectedDocument[DOC_ID_FIELD]
        );
      } else {
        newSelectedDocuments.push(selectedDocument);
      }
      setSelectedDocuments(newSelectedDocuments);
    },
    [selectedDocuments, setSelectedDocuments]
  );

  const onHeaderSelectionChange = React.useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      if (!setSelectedDocuments) {
        return;
      }
      const checkboxElement = event.syntheticEvent.target as HTMLInputElement;
      const checked = checkboxElement.checked;
      const newSelectedDocuments: GearSelectedDocument[] = [];
      if (checked) {
        const dataItems: GearGridDocument[] = event.dataItems;
        dataItems.forEach(item => {
          newSelectedDocuments.push({
            [DOC_ID_FIELD]: idGetter(item),
            [CONTENT_TYPE_FIELD]: contentTypeGetter(item)
          });
        });
      }
      setSelectedDocuments(newSelectedDocuments);
    },
    [setSelectedDocuments]
  );

  const pageSizeValue = useMemo(() => queryRequest?.size || DEFAULT_ROWS_PER_PAGE, [queryRequest?.size]);

  const rowsCount = useMemo(() => data?.totalCount, [data?.totalCount]);

  const pageChange = (event: GridPageChangeEvent) => {
    const { skip, take } = event.page;
    if (queryRequest && setQueryRequest) {
      const newQueryRequest: GearSearchQueryRequest = {
        ...queryRequest,
        skip,
        size: take
      };
      setQueryRequest(newQueryRequest);
    }
  };

  const [gridDimensions, setGridDimensions] = useState<ElementDimensions>({ width: 0, height: 0 });
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    if (ref && ref.current) {
      const paddingSize = 30;
      const height = document.body.offsetHeight - ref.current.getBoundingClientRect().top - paddingSize - 60;
      const width = ref.current.getBoundingClientRect().width - paddingSize;
      setGridDimensions({
        height,
        width
      });
      if (gridData.length === 0) {
        const tableThread = ref.current.getElementsByClassName('k-table-thead')[0] as HTMLElement;
        const tableThreadWidth = tableThread.getBoundingClientRect().width;
        const noRecordsWrapper = ref.current.getElementsByClassName('k-grid-table-wrap')[0] as HTMLElement;
        noRecordsWrapper.style.width = `${tableThreadWidth}px`;
      }
    }
  }, [windowDimensions, gridData, selectedIndex]);

  return (
    <div ref={ref} className="gearGridContainer">
      {(isLoading || openDocumentLoading || downlaodFilesLoading || defaultViewLoading) && (
        <GearLoader
          type={openDocumentLoading || downlaodFilesLoading ? 'pulsing' : 'infinite-spinner'}
          textMessageKey={
            downlaodFilesLoading
              ? 'custom.common.loader.preparingDownload'
              : openDocumentLoading
                ? 'custom.common.loader.opening'
                : undefined
          }
        />
      )}
      <Tooltip openDelay={500} anchorElement="target" parentTitle={true} position="left">
        <Grid
          className="gearGrid"
          style={{ height: gridDimensions.height }}
          sortable={true}
          sort={sort}
          onSortChange={(event: GridSortChangeEvent) => sortChange(event.sort)}
          onFilterChange={(event: GridFilterChangeEvent) => {
            if (setCompositeFilterDescriptor) {
              setCompositeFilterDescriptor(event.filter);
            }
          }}
          pageable={{
            buttonCount: 4,
            pageSizes: GRID_PAGE_SIZES,
            pageSizeValue
          }}
          scrollable="scrollable"
          skip={queryRequest?.skip}
          take={pageSizeValue}
          total={rowsCount}
          onPageChange={pageChange}
          data={gridData.map(item => ({
            ...item,
            [SELECTED_FIELD]: selectedDocuments.find(doc => doc[DOC_ID_FIELD] === idGetter(item)) ? true : false
          }))}
          cellRender={cellRender}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: 'multiple'
          }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          headerCellRender={headerRender}
          resizable={true}
          size={'small'}
        >
          <GridNoRecords>{IntlHelper.toLangStr(localization, 'custom.common.emptyGridMessage')}</GridNoRecords>
          <GridToolbar className="gearToolbarContainer">
            <GearToolbar />
          </GridToolbar>
          <GridColumn
            className="centeredField"
            headerClassName="centeredField"
            field={SELECTED_FIELD}
            headerSelectionValue={selectedDocuments.length === gridData.length}
            width={45}
            resizable={false}
          />
          <GridColumn field={NOTE_FIELD} headerCell={HiddenHeader} width={10} />
          {columns?.map(column => (
            <GridColumn
              key={column.id}
              field={column.name}
              title={column.displayName}
              filter={column.type === GEAR_FIELD_TYPE.DATETIME ? GEAR_FIELD_TYPE.DATE : column.type}
              width={column.width}
              columnMenu={columnMenuProps => (
                <ColumnMenu
                  {...columnMenuProps}
                  onSortChange={sortChange}
                  sortable={column.sortable}
                  filterable={column.filterable}
                  filter={compositeFilterDescriptor}
                  field={column.name}
                  fieldFacets={data?.facets && (data.facets[column.name] as GearFacetValue[] | undefined)}
                />
              )}
            />
          ))}
        </Grid>
      </Tooltip>
    </div>
  );
};

export default GearGrid;
