import * as React from 'react';
import { useState, useEffect } from 'react';
import { NotificationGroup, Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';

const DEFAULT_TIMEOUT = 6000;

export interface GearNotificationProps {
  show: boolean;
  type: 'none' | 'success' | 'error' | 'warning' | 'info';
  message: string;
  timeout?: number;
  onClose?: () => void;
}

export const GearNotification: React.FC<GearNotificationProps> = props => {
  const [show, setShow] = useState(props.show);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setShow(false);
        if (props.onClose) {
          props.onClose();
        }
      }, props.timeout || DEFAULT_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <NotificationGroup className={'gearNotification'}>
      <Fade enter={true} exit={true}>
        {show && (
          <Notification
            type={{ style: props.type, icon: true }}
            closable={true}
            onClose={() => {
              setShow(false);
              if (props.onClose) {
                props.onClose();
              }
            }}
          >
            <span>{props.message}</span>
          </Notification>
        )}
      </Fade>
    </NotificationGroup>
  );
};
