import { ChipList, ChipListChangeEvent } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { GearSettingMenuItem } from '../../../Model/GearSettingMenuItem';
import { GearAdminCenterMenuItemType } from '../../../global/enums/GearAdminCenterMenuItemType';

interface AdminCenterMenuProps {
  menu: GearSettingMenuItem[];
  value?: GearAdminCenterMenuItemType;
  onSelectedMenuItemChanged: (value: GearAdminCenterMenuItemType) => void;
}

export const AdminCenterMenu: React.FC<AdminCenterMenuProps> = props => {
  const handleChange = (event: ChipListChangeEvent): void => {
    if (event.value) {
      props.onSelectedMenuItemChanged(event.value);
    }
  };

  return (
    <ChipList
      className="helpCenterMenu"
      defaultData={props.menu}
      selection="single"
      value={props.value}
      onChange={handleChange}
    />
  );
};
