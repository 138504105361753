import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import * as React from 'react';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { RoutePath } from '../../global/enums/RoutePath';
import { GearUiAdminConfigWriteType } from '../../Model/uiAdminConfig/GearUiAdminConfigWriteType';

interface SettingsNavigationProps {
  uiAdminConfigWritePermissions: GearUiAdminConfigWriteType;
}

export const SettingsNavigation: React.FC<SettingsNavigationProps> = props => {
  const { pathname } = useLocation();
  const localization = useLocalization();

  const viewsText = IntlHelper.toLangStr(localization, 'custom.settings.views.title');
  const helpCenterText = IntlHelper.toLangStr(localization, 'custom.settings.helpCenter.title');
  const adminCenterText = IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.title');

  const availableLinks = [
    {
      to: `/${RoutePath.SETTINGS}/${RoutePath.SETTINGS_VIEWS}`,
      text: viewsText
    },
    {
      to: `/${RoutePath.SETTINGS}/${RoutePath.SETTINGS_HELP_CENTER}`,
      text: helpCenterText
    },
    ...(props.uiAdminConfigWritePermissions.maintenancePage || props.uiAdminConfigWritePermissions.messageBar
      ? [
          {
            to: `/${RoutePath.SETTINGS}/${RoutePath.SETTINGS_ADMIN_CENTER}`,
            text: adminCenterText
          }
        ]
      : [])
  ];

  return (
    <div className="navigation-container">
      <div className="k-tilelayout-item-header k-card-header k-cursor-grab">
        {availableLinks.map(link => (
          <Link
            key={link.text}
            to={link.to}
            className={`dashboard-button ${link.to === pathname ? 'dashboard-button--active' : ''}`}
            style={{ textDecoration: 'none' }}
          >
            <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base" type="button">
              <span className="k-icon k-i-user k-button-icon"></span>
              <span className="k-button-text">{link.text}</span>
            </button>
          </Link>
        ))}
      </div>
    </div>
  );
};
