import * as React from 'react';
import { DocumentsContext } from './DocumentsContext';
import { useContext, useState } from 'react';
import { GearSelectedDocument } from '../../Model/GearSelectedDocument';
import ServicesContext from '../../global/services/ServicesContext';
import JSZip from 'jszip';
import { KyResponse } from 'ky';
import { buildDocumentEndpointPath } from './DocumentsService';
import { API_URL } from '../../config/consts';
import { useCurrentUserContext } from '../currentUser/CurrentUserContext';

interface DocumentsProviderProps {
  children?: React.ReactNode;
}

export const DocumentsProvider: React.FC<DocumentsProviderProps> = ({ children }) => {
  const servicesCtx = useContext(ServicesContext);
  const [selectedDocuments, setSelectedDocuments] = useState<GearSelectedDocument[]>([]);
  const [openDocumentLoading, setOpenDocumentLoading] = useState<boolean>(false);
  const [downlaodFilesLoading, setDownlaodFilesLoading] = useState<boolean>(false);

  const { selectedIndex } = useCurrentUserContext();

  const getDocumentContent = async (
    contentType: string,
    documentId: string,
    indexId?: number,
    displayInBrowser?: boolean
  ) => {
    if (servicesCtx?.documentsService) {
      return servicesCtx.documentsService.getDocumentContent(
        contentType,
        documentId,
        indexId,
        displayInBrowser || false
      );
    }
    throw new Error('Services context not initialized');
  };

  const getFileName = async (response: KyResponse) => {
    // filename can be fetched from Content-Disposition header
    const disposition = await response.headers.get('Content-Disposition');
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    if (!disposition) {
      console.error(`Filename not found`);
      return;
    }
    if (utf8FilenameRegex.test(disposition)) {
      const regExpExecArray = utf8FilenameRegex.exec(disposition);
      if (!regExpExecArray || regExpExecArray.length < 2) {
        console.error(`Filename not found`);
        return;
      }
      return decodeURIComponent(regExpExecArray[1]);
    } else {
      const filenameStart = disposition.toLowerCase().indexOf('filename=');
      if (filenameStart >= 0) {
        const partialDisposition = disposition.slice(filenameStart);
        const matches = asciiFilenameRegex.exec(partialDisposition);
        if (matches != null && matches[2]) {
          return matches[2];
        }
      }
      console.error(`Filename not found`);
      return;
    }
  };

  const openDocument = async (contentType: string, documentId: string) => {
    setOpenDocumentLoading(true);
    const redirectDocumentUrl =
      API_URL + '/' + buildDocumentEndpointPath(contentType, documentId, selectedIndex?.id, true);
    window.open(redirectDocumentUrl);
    setOpenDocumentLoading(false);
  };

  const downloadBlobFile = (blob: Blob, fileName: string) => {
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  const downloadSingleFile = async () => {
    const documentContent = await getDocumentContent(
      selectedDocuments[0].Cps_ContentType,
      selectedDocuments[0].Cps_DocId,
      selectedIndex?.id
    );
    const blob = await documentContent.blob();
    const fileName = await getFileName(documentContent);
    if (blob && fileName) {
      downloadBlobFile(blob, fileName);
    }
  };

  const compressFilesAndDownloadZip = async () => {
    const zip = new JSZip();
    await Promise.all(
      selectedDocuments.map(async selectedDocument => {
        const documentContent = await getDocumentContent(
          selectedDocument.Cps_ContentType,
          selectedDocument.Cps_DocId,
          selectedIndex?.id
        );
        const blob = await documentContent.blob();
        const fileName = await getFileName(documentContent);
        if (blob && fileName) {
          zip.file(fileName, blob);
        }
      })
    );
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    downloadBlobFile(zipBlob, 'Gear Documents.zip');
  };

  const downloadFiles = async () => {
    setDownlaodFilesLoading(true);
    switch (selectedDocuments.length) {
      case 0:
        break;
      case 1:
        await downloadSingleFile();
        break;
      default:
        await compressFilesAndDownloadZip();
        break;
    }
    setDownlaodFilesLoading(false);
  };

  return (
    <DocumentsContext.Provider
      value={{
        openDocumentLoading,
        downlaodFilesLoading,
        selectedDocuments,
        setSelectedDocuments,
        openDocument,
        downloadFiles
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};
