import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import * as React from 'react';
import { ReactNode } from 'react';
import { reactPlugin } from '../../context/applicationInsights/ApplicationInsightsService';

interface ApplicationInsightsWrapperProps {
  children: ReactNode;
}

export const ApplicationInsightsWrapper: React.FC<ApplicationInsightsWrapperProps> = props => {
  return <AppInsightsContext.Provider value={reactPlugin}>{props.children}</AppInsightsContext.Provider>;
};
