import { KyResponse, Options } from 'ky';
import { ApiResponse } from '../../Model/ApiResponse';
import useKyClient from '../../global/hooks/useKyClient';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export const ExecuteEndpoint = async <TResult>(
  endpoint: string,
  httpMethod: HttpMethod,
  options?: Options
): Promise<TResult> => {
  const client = useKyClient();
  let response: KyResponse;
  switch (httpMethod) {
    case 'GET':
      response = await client.get(endpoint, options);
      break;
    case 'POST':
      response = await client.post(endpoint, options);
      break;
    case 'PUT':
      response = await client.put(endpoint, options);
      break;
    case 'DELETE':
      response = await client.delete(endpoint, options);
      break;
    case 'PATCH':
      response = await client.patch(endpoint, options);
      break;
    default:
      response = await client.get(endpoint, options);
  }

  try {
    const gearResponse: ApiResponse = await response.json();

    if (gearResponse.isValid) {
      return gearResponse.result;
    }
    if (gearResponse.errorMessage) {
      throw new Error(gearResponse.errorMessage);
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
  throw new Error('Unspecified error.');
};

export const ExecuteGetFile = async (endpoint: string): Promise<KyResponse> => {
  const client = useKyClient();
  try {
    return await client.get(endpoint);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
