import { createContext, useContext } from 'react';
import { GearSearchQueryResult } from '../../Model/GearSearchQueryResult';
import { GearSearchQueryRequest } from '../../Model/GearSearchQueryRequest';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GearSearchExportRequest } from '../../Model/GearSearchExportRequest';

interface ISearchContext {
  data?: GearSearchQueryResult;
  isLoading?: boolean;
  queryRequest?: GearSearchQueryRequest;
  setQueryRequest?: (queryRequest: GearSearchQueryRequest) => void;
  compositeFilterDescriptor?: CompositeFilterDescriptor;
  setCompositeFilterDescriptor?: (newCompositeFilterDescriptor: CompositeFilterDescriptor | undefined) => void;
  handleTrigerExportToCsv?: (querySearchExport: GearSearchExportRequest) => void;
}

const defaultValue: ISearchContext = {};

export const SearchContext = createContext<ISearchContext>(defaultValue);

export const useSearchContext = () => useContext(SearchContext);
