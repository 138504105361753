import * as React from 'react';
import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { filterIcon, arrowUpIcon, arrowDownIcon } from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';

interface HeaderCellProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  tdElement: React.ReactNode;
  headerProps: GridHeaderCellProps;
  compositeFilterDescriptor: CompositeFilterDescriptor | undefined;
}

export const HeaderCell: React.FC<HeaderCellProps> = props => {
  const { tdElement, headerProps, compositeFilterDescriptor } = props;
  const filteredField = compositeFilterDescriptor
    ? (compositeFilterDescriptor?.filters as Array<CompositeFilterDescriptor>).find(
        filter =>
          filter && filter.filters.length > 0 && (filter.filters[0] as FilterDescriptor).field === headerProps.field
      )
    : null;

  const sortDir: 'asc' | 'desc' | undefined = React.useMemo(() => {
    if (!headerProps.columnMenuWrapperProps.sortable) {
      return undefined;
    }
    const foundSortDescriptor = headerProps.columnMenuWrapperProps.sort?.find(
      (value: SortDescriptor) => value.field === headerProps.field
    );
    if (foundSortDescriptor) {
      return foundSortDescriptor.dir;
    }
    return undefined;
  }, [headerProps]);

  const headerCol = tdElement as React.ReactElement;

  return (
    <div title={headerProps.title}>
      {headerCol && headerCol.props && headerCol.props.children.length > 0 && (
        <div className="columnHeader">
          <div className="k-link">
            <div className="k-column-title" onClick={headerProps.onClick}>
              {headerProps.title}
            </div>
            {sortDir && <SvgIcon className="filterIcon" icon={sortDir === 'asc' ? arrowUpIcon : arrowDownIcon} />}
            {filteredField && compositeFilterDescriptor && <SvgIcon className="filterIcon" icon={filterIcon} />}
            {headerCol.props.children[1]}
          </div>
        </div>
      )}
    </div>
  );
};
