export const SETTINGS_VIEW_FIELD = {
  ID: 'id',
  NAME: 'name',
  WIDTH: 'width',
  LINK: 'isLink',
  REMOVE: 'remove',
  IS_NEW: 'isNew'
} as const;

export interface SettingsViewDataItem {
  [SETTINGS_VIEW_FIELD.ID]: number;
  [SETTINGS_VIEW_FIELD.NAME]: string;
  [SETTINGS_VIEW_FIELD.WIDTH]: number;
  [SETTINGS_VIEW_FIELD.LINK]: boolean;
  [SETTINGS_VIEW_FIELD.REMOVE]?: string;
  [SETTINGS_VIEW_FIELD.IS_NEW]?: boolean;
}
