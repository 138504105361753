import { createContext, useContext } from 'react';
import { GearView } from '../../Model/GearView';
import { SettingsViewDataItem } from '../../components/settings/viewsManagement/SettingsViewDataItem';
import { PrivateViewState } from '../../components/settings/viewsManagement/privateView/PrivateViewState';
import { GlobalFilterState } from '../../components/grid/toolbar/globalFilters/GlobalFilterState';

interface IViewsContext {
  defaultUserView: GearView | undefined;
  defaultUserViewLoading: boolean;
  defaultSystemView: GearView | undefined;
  defaultSystemViewLoading: boolean;
  privateView: GearView | undefined;
  privateViewLoading: boolean;
  mutatePrivateViewLoading: boolean;
  privateViewState: {
    data: SettingsViewDataItem[];
    activeItem: SettingsViewDataItem | null;
  };
  setPrivateViewState?: (viewState: PrivateViewState) => void;
  onPrivateViewDragStart?: (dataItem: SettingsViewDataItem) => void;
  onPrivateViewReorder?: (dataItem: SettingsViewDataItem, direction: 'before' | 'after' | null) => void;
  onRemovePrivateView?: () => void;
  onCreateUpdatePrivateView?: () => void;
  globalFiltersState?: GlobalFilterState[];
  setGlobalFiltersState?: (globalFiltersState: GlobalFilterState[]) => void;
  globalFilterDateFieldSelected?: GlobalFilterState;
  setGlobalFilterDateFieldSelected?: (globalFilterDateFieldSelected?: GlobalFilterState) => void;
}

export const ViewsContext = createContext<IViewsContext>({
  defaultUserView: undefined,
  defaultUserViewLoading: false,
  defaultSystemView: undefined,
  defaultSystemViewLoading: false,
  privateView: undefined,
  privateViewLoading: false,
  mutatePrivateViewLoading: false,
  privateViewState: {
    data: [],
    activeItem: null
  }
});

export const useViewsContext = () => useContext(ViewsContext);
