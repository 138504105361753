import { createContext, useContext } from 'react';
import { GearUiAdminConfigWriteType } from '../../Model/uiAdminConfig/GearUiAdminConfigWriteType';
import { GEAR_UI_ADMIN_CONFIG_TYPE, GearUiAdminConfigType } from '../../Model/uiAdminConfig/GearUiAdminConfigType';
import { GearUiAdminConfigState } from '../../Model/uiAdminConfig/GearUiAdminConfigState';

interface IUiAdminConfigsContext {
  refreshUiAdminConfigState: (configKey: GearUiAdminConfigType) => void;
  setConfigWritePermissionsKeys?: (writeKeys: GearUiAdminConfigType[]) => void;
  isLoading: boolean;
  upsertUiAdminConfigEntry: (key: GearUiAdminConfigType, body: string) => void;
  uiAdminConfigWritePermissions?: GearUiAdminConfigWriteType;
  uiAdminConfigPreviewState: GearUiAdminConfigState;
  setUiAdminConfigPreviewState?: (messageBarPreview: GearUiAdminConfigState) => void;
  uiAdminConfigState: GearUiAdminConfigState;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const UiAdminConfigsContext = createContext<IUiAdminConfigsContext>({
  isLoading: false,
  upsertUiAdminConfigEntry: () => {},
  uiAdminConfigPreviewState: {
    [GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR]: undefined,
    [GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE]: undefined
  },
  uiAdminConfigState: {},
  refreshUiAdminConfigState: () => {}
});

export const useUiAdminConfigsContext = () => useContext(UiAdminConfigsContext);
