import * as React from 'react';
import { GridColumnMenuFilter, GridColumnMenuProps, GridColumnMenuSort } from '@progress/kendo-react-grid';
import { GridColumnMenuCheckboxFilterWrapper } from './GridColumnMenuCheckboxFilterWrapper';
import { GearFacetValue } from '../../../Model/GearFacets';
import { gridFilterOperators } from '../../../config/gridFilterOperators';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

interface ColumnMenuProps extends GridColumnMenuProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  field: string;
  fieldFacets: GearFacetValue[] | undefined;
}

export const ColumnMenu: React.FC<ColumnMenuProps> = props => {
  const { field, fieldFacets, filter } = props;
  const [filterExpanded, setFilterExpanded] = React.useState<boolean>(false);
  React.useEffect(() => {
    const activeFilter = filter?.filters.some(f1 =>
      (f1 as CompositeFilterDescriptor).filters.some(f2 => (f2 as FilterDescriptor).field === field)
    );
    if (activeFilter && !filterExpanded) {
      setFilterExpanded(true);
    }
  }, [filter]);

  return (
    <div>
      <GridColumnMenuSort {...props} />
      {fieldFacets ? (
        <GridColumnMenuCheckboxFilterWrapper
          {...props}
          field={field}
          fieldFacets={fieldFacets}
          expanded={filterExpanded}
          onExpandChange={setFilterExpanded}
        />
      ) : (
        <GridColumnMenuFilter
          {...props}
          hideSecondFilter={true}
          filterOperators={gridFilterOperators}
          expanded={filterExpanded}
          onExpandChange={setFilterExpanded}
        />
      )}
    </div>
  );
};
