import { CreateUpdateViewRequestDto } from '../../Model/CreateUpdateViewRequestDto';
import { GearView } from '../../Model/GearView';
import { ExecuteEndpoint } from '../../components/common/BaseApiService';

export class ViewsService {
  public async getDefaultView(privIfExists: boolean, locale?: string, indexId?: number): Promise<GearView> {
    const searchParams = new URLSearchParams();
    if (locale) {
      searchParams.append('locale', locale);
    }
    if (privIfExists == true) {
      searchParams.append('privIfExists', 'true');
    }
    if (indexId !== undefined) {
      searchParams.append('indexId', indexId.toString());
    }
    const queryParams = searchParams.size > 0 ? `?${searchParams.toString()}` : '';
    return ExecuteEndpoint(`views/default${queryParams}`, 'GET');
  }

  public async getPrivateView(locale?: string, indexId?: number): Promise<GearView> {
    const searchParams = new URLSearchParams();
    if (locale) {
      searchParams.append('locale', locale);
    }
    if (indexId !== undefined) {
      searchParams.append('indexId', indexId.toString());
    }
    const queryParams = searchParams.size > 0 ? `?${searchParams.toString()}` : '';
    return ExecuteEndpoint(`views/private${queryParams}`, 'GET');
  }

  public async createUpdatePrivateView(createUpdateViewRequest: CreateUpdateViewRequestDto): Promise<boolean> {
    return ExecuteEndpoint(`views/private`, 'POST', { json: createUpdateViewRequest });
  }

  public async removePrivateView(indexId?: number): Promise<boolean> {
    const queryParams = indexId !== undefined ? `?indexId=${indexId}` : '';
    return ExecuteEndpoint(`views/private${queryParams}`, 'DELETE');
  }
}
