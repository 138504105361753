import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { IntlHelper } from '../../../../../helpers/IntlHelper';
import { StackLayout } from '@progress/kendo-react-layout';
import { useViewsContext } from '../../../../../context/view/ViewsContext';
import { DEFAULT_COLUMN_WIDTH } from '../../../../../config/consts';
import { SETTINGS_VIEW_FIELD, SettingsViewDataItem } from '../../SettingsViewDataItem';
import { plusIcon, xCircleIcon } from '@progress/kendo-svg-icons';
import { ConfirmDialog } from '../../../../common/ConfirmDialog';
import { GEAR_CONFIRM_DIALOG_STATE, GearConfirmDialogState } from '../../../../../Model/GearConfirmDialogState';

export const PrivateViewHeaderButtons: React.FC = () => {
  const localization = useLocalization();
  const { privateViewState, setPrivateViewState, privateView, onCreateUpdatePrivateView, onRemovePrivateView } =
    useViewsContext();
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState<boolean>(false);

  const handleAddField = () => {
    if (!setPrivateViewState) {
      return;
    }
    const newPrivateViewData = [...privateViewState.data];
    newPrivateViewData.unshift({
      [SETTINGS_VIEW_FIELD.ID]: 0,
      [SETTINGS_VIEW_FIELD.NAME]: '',
      [SETTINGS_VIEW_FIELD.WIDTH]: DEFAULT_COLUMN_WIDTH,
      [SETTINGS_VIEW_FIELD.LINK]: false,
      [SETTINGS_VIEW_FIELD.IS_NEW]: true
    } as SettingsViewDataItem);
    setPrivateViewState({
      ...privateViewState,
      data: newPrivateViewData
    });
  };

  const handleConfirmRemoveDialog = (confirmed: GearConfirmDialogState): void => {
    if (confirmed === GEAR_CONFIRM_DIALOG_STATE.YES) {
      if (onRemovePrivateView) {
        onRemovePrivateView();
      }
    } else {
      setRemoveDialogVisible(false);
    }
  };

  return (
    <>
      {removeDialogVisible && (
        <ConfirmDialog
          message={IntlHelper.toLangStr(localization, 'custom.settings.views.private.removeConfirmation')}
          onConfirm={handleConfirmRemoveDialog}
        />
      )}
      <StackLayout
        className="privateViewHeaderButtons"
        orientation={'horizontal'}
        align={{ horizontal: 'end', vertical: 'middle' }}
      >
        <Button
          svgIcon={plusIcon}
          className="k-toolbar-button"
          fillMode="flat"
          title={IntlHelper.toLangStr(localization, 'custom.settings.views.private.addField')}
          onClick={handleAddField}
          disabled={!!privateViewState.data.find((item: SettingsViewDataItem) => !item[SETTINGS_VIEW_FIELD.ID])}
        >
          {IntlHelper.toLangStr(localization, 'custom.settings.views.private.addField')}
        </Button>
        <Button
          className="k-toolbar-button"
          themeColor="dark"
          title={IntlHelper.toLangStr(localization, 'custom.common.save')}
          disabled={!!privateViewState.data.find((item: SettingsViewDataItem) => !item[SETTINGS_VIEW_FIELD.ID])}
          onClick={onCreateUpdatePrivateView}
        >
          {IntlHelper.toLangStr(localization, 'custom.common.save')}
        </Button>
        {privateView && (
          <Button
            svgIcon={xCircleIcon}
            className="k-toolbar-button"
            themeColor="error"
            fillMode="flat"
            title={IntlHelper.toLangStr(localization, 'custom.settings.views.private.resetDefault')}
            onClick={() => setRemoveDialogVisible(true)}
          >
            {IntlHelper.toLangStr(localization, 'custom.settings.views.private.resetDefault')}
          </Button>
        )}
      </StackLayout>
    </>
  );
};
