import { FieldResponseDto } from '../../Model/responseDtos/FieldResponseDto';
import { ExecuteEndpoint } from '../../components/common/BaseApiService';

export class FieldsService {
  public async getAllFields(locale?: string, indexId?: number): Promise<FieldResponseDto[]> {
    const searchParams = new URLSearchParams();
    if (locale) {
      searchParams.append('locale', locale);
    }
    if (indexId !== undefined) {
      searchParams.append('indexId', indexId.toString());
    }
    const queryParams = searchParams.size > 0 ? `?${searchParams.toString()}` : '';

    return ExecuteEndpoint(`fields${queryParams}`, 'GET');
  }
}
