import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import ServicesContext from '../../global/services/ServicesContext';
import { GearSearchQueryResult } from '../../Model/GearSearchQueryResult';
import { GearSearchQueryRequest } from '../../Model/GearSearchQueryRequest';
import { searchKeys } from './queryKeyFactory';

export function useSearch(querySearch: GearSearchQueryRequest): UseQueryResult<GearSearchQueryResult, unknown> {
  const servicesCtx = useContext(ServicesContext);

  const getSearchResults = () => {
    if (servicesCtx?.searchService) {
      return servicesCtx.searchService.getSearchResults(querySearch);
    }
    throw new Error('Services context not initialized');
  };

  return useQuery({
    queryKey: searchKeys.filteredSearch(querySearch),
    queryFn: getSearchResults,
    retry: 0,
    staleTime: 180000,
    cacheTime: 180000,
    enabled: !!querySearch
  });
}
