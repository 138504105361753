import { Button } from '@progress/kendo-react-buttons';
import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { EMPTY_VALUE } from '../../../config/consts';
import { GearGridCell } from './GearGridCell';
import { hyperlinkOpenIcon } from '@progress/kendo-svg-icons';

interface LinkedCellProps extends GridCellProps {
  onLinkClicked: () => void;
}

const LinkedCell: React.FC<LinkedCellProps> = props => {
  const field = props.field || EMPTY_VALUE;
  const value = props.dataItem[field];
  return (
    <GearGridCell {...props} title={value?.toString()}>
      {value === null ? (
        EMPTY_VALUE
      ) : (
        <Button
          className="gridIcon"
          svgIcon={hyperlinkOpenIcon}
          title={value?.toString()}
          fillMode="link"
          onClick={props.onLinkClicked}
        >
          {value?.toString()}
        </Button>
      )}
    </GearGridCell>
  );
};

export default LinkedCell;
