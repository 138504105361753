import * as React from 'react';
import { BrowserUtils } from '@azure/msal-browser';
import { useEffect } from 'react';
import GearLoader from '../components/common/GearLoader';
import { msalInstance } from '..';

const Logout: React.FC = () => {
  useEffect(() => {
    msalInstance
      .logoutRedirect({
        account: msalInstance.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe()
      })
      .catch(err => {
        console.error(err);
      });
  }, [msalInstance]);

  return <GearLoader />;
};

export default Logout;
