import * as React from 'react';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { GearFieldWrapper } from '../GearFieldWrapper';
import { Editor, EditorChangeEvent, EditorTools, EditorUtils } from '@progress/kendo-react-editor';

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
  ForeColor,
  BackColor,
  CleanFormatting
} = EditorTools;

export const FormRichTextEditor: React.FC<FieldRenderProps> = fieldRenderProps => {
  const editor = React.createRef<Editor>();
  const setHtml = (event: EditorChangeEvent) => {
    if (editor.current && event.html !== fieldRenderProps.value) {
      const view = editor.current.view;
      if (view) {
        fieldRenderProps.onChange({
          ...event,
          value: event.html
        });
      }
    }
  };

  React.useEffect(() => {
    if (editor.current) {
      const view = editor.current.view;
      if (view && fieldRenderProps.value) {
        EditorUtils.setHtml(view, fieldRenderProps.value);
      }
    }
  }, []);

  return (
    <GearFieldWrapper {...fieldRenderProps}>
      <Editor
        ref={editor}
        tools={[
          [Bold, Italic, Underline, Strikethrough],
          ForeColor,
          BackColor,
          CleanFormatting,
          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
          [Indent, Outdent],
          [OrderedList, UnorderedList],
          FontSize,
          FormatBlock,
          [Undo, Redo],
          [Link, Unlink, InsertImage, ViewHtml],
          [InsertTable],
          [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
          [DeleteRow, DeleteColumn, DeleteTable],
          [MergeCells, SplitCell]
        ]}
        onChange={(event: EditorChangeEvent) => setHtml(event)}
      />
    </GearFieldWrapper>
  );
};
