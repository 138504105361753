import * as React from 'react';
import { ViewsContext } from './ViewsContext';
import { useGetDefaultView } from './useGetDefaultView';
import { useState } from 'react';
import {
  SETTINGS_VIEW_FIELD,
  SettingsViewDataItem
} from '../../components/settings/viewsManagement/SettingsViewDataItem';
import { PrivateViewState } from '../../components/settings/viewsManagement/privateView/PrivateViewState';
import { useGetPrivateView } from './useGetPrivateView';
import { useRemovePrivateView } from './useRemovePrivateView';
import { useNotificationContext } from '../../global/hooks/useNotificationContext';
import { useCreateUpdatePrivateView } from './useCreateUpdatePrivateView';
import { CreateUpdateViewRequestDto } from '../../Model/CreateUpdateViewRequestDto';
import { GearFieldRequest } from '../../Model/GearFieldRequest';
import { useIsMutating } from '@tanstack/react-query';
import { mutateViewsKeys } from './mutateKeyFactory';
import { useGlobalizationContext } from '../globalization/GlobalizationContext';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { GlobalFilterState } from '../../components/grid/toolbar/globalFilters/GlobalFilterState';
import { useCurrentUserContext } from '../currentUser/CurrentUserContext';

interface ViewsProviderProps {
  children?: React.ReactNode;
}

const initPrivateViewState: PrivateViewState = {
  data: [],
  activeItem: null
};
export const ViewsProvider: React.FC<ViewsProviderProps> = ({ children }) => {
  const localization = useLocalization();
  const { selectedLocale } = useGlobalizationContext();
  const { selectedIndex } = useCurrentUserContext();
  const { data: defaultUserView, isLoading: defaultUserViewLoading } = useGetDefaultView(
    true,
    selectedLocale,
    selectedIndex?.id
  );
  const { data: defaultSystemView, isLoading: defaultSystemViewLoading } = useGetDefaultView(
    false,
    selectedLocale,
    selectedIndex?.id
  );
  const { data: privateView, isLoading: privateViewLoading } = useGetPrivateView(selectedLocale, selectedIndex?.id);
  const { mutate: mutateRemovePrivateView } = useRemovePrivateView(selectedLocale, selectedIndex?.id);
  const [privateViewState, setPrivateViewState] = useState<PrivateViewState>(initPrivateViewState);
  const [globalFiltersState, setGlobalFiltersState] = useState<GlobalFilterState[]>();
  const [globalFilterDateFieldSelected, setGlobalFilterDateFieldSelected] = useState<GlobalFilterState>();

  const createUpdateViewRequest: CreateUpdateViewRequestDto = React.useMemo(() => {
    const fields = privateViewState.data.map(
      item =>
        ({
          id: item[SETTINGS_VIEW_FIELD.ID],
          width: item[SETTINGS_VIEW_FIELD.WIDTH],
          freezed: false, // TO DO: add possibility to handle Grid freezing
          isDocumentLink: item[SETTINGS_VIEW_FIELD.LINK]
        }) as GearFieldRequest
    );
    return { fields, indexId: selectedIndex?.id };
  }, [privateViewState.data]);
  const { mutate: mutateCreateUpdatePrivateView } = useCreateUpdatePrivateView(
    createUpdateViewRequest,
    selectedLocale,
    selectedIndex?.id
  );

  const { showNotification } = useNotificationContext();

  const handleReorderPrivateView = (dataItem: SettingsViewDataItem, direction: 'before' | 'after' | null) => {
    if (privateViewState.activeItem === dataItem) {
      return;
    }
    const reorderedData = privateViewState.data.slice();
    const prevIndex = reorderedData.findIndex(p => p === privateViewState.activeItem);
    let nextIndex = reorderedData.findIndex(p => p === dataItem) + (direction === 'before' ? -1 : 0);
    if (prevIndex > nextIndex) {
      nextIndex++;
    }
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, privateViewState.activeItem || reorderedData[0]);

    setPrivateViewState({ ...privateViewState, data: reorderedData });
  };

  const handleRemovePrivateView = () => {
    mutateRemovePrivateView(
      {},
      {
        onSuccess: () => {
          setPrivateViewState(initPrivateViewState);
          showNotification({
            type: 'success',
            message: IntlHelper.toLangStr(localization, 'custom.notifications.privateViews.remove')
          });
        }
      }
    );
  };

  const handleCreateUpdatePrivateView = () => {
    mutateCreateUpdatePrivateView(
      { createUpdateViewRequest },
      {
        onSuccess: () => {
          showNotification({
            type: 'success',
            message: IntlHelper.toLangStr(
              localization,
              privateView ? 'custom.notifications.privateViews.update' : 'custom.notifications.privateViews.create'
            )
          });
        }
      }
    );
  };

  React.useEffect(() => {
    setPrivateViewState(initPrivateViewState);
  }, [selectedIndex?.id]);

  const isCreateUpdatePrivateViewMutation = !!useIsMutating(mutateViewsKeys.createUpdatePrivateView);
  const isRemovePrivateViewMutation = !!useIsMutating(mutateViewsKeys.removePrivateView);

  return (
    <ViewsContext.Provider
      value={{
        defaultUserView,
        defaultUserViewLoading,
        defaultSystemView,
        defaultSystemViewLoading,
        privateView,
        privateViewState,
        privateViewLoading,
        mutatePrivateViewLoading: isCreateUpdatePrivateViewMutation || isRemovePrivateViewMutation,
        setPrivateViewState,
        onPrivateViewDragStart: dataItem => setPrivateViewState({ ...privateViewState, activeItem: dataItem }),
        onPrivateViewReorder: handleReorderPrivateView,
        onRemovePrivateView: handleRemovePrivateView,
        onCreateUpdatePrivateView: handleCreateUpdatePrivateView,
        globalFiltersState,
        setGlobalFiltersState,
        globalFilterDateFieldSelected,
        setGlobalFilterDateFieldSelected
      }}
    >
      {children}
    </ViewsContext.Provider>
  );
};
