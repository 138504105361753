import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { Icon } from '@fluentui/react';
import { EXTENSION_FIELD } from '../../../config/consts';
import { GearGridCell } from './GearGridCell';

export interface IExtensionCellProps extends GridCellProps {}

export const ExtensionCell: React.FC<IExtensionCellProps> = props => {
  const extension = props.dataItem[EXTENSION_FIELD];
  const fileTypeIconProps = getFileTypeIconProps({ extension, size: 20, imageFileType: 'svg' });
  return (
    <GearGridCell {...props} title={fileTypeIconProps['aria-label'] || ''}>
      <Icon style={{ verticalAlign: 'middle' }} {...fileTypeIconProps} />
    </GearGridCell>
  );
};
