import * as React from 'react';
import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';
import LanguageButton from './LanguageButton';
import { GlobalSearchFilter } from '../grid/toolbar/globalFilters/GlobalSearchFilter';
import SettingsButton from './SettingsButton';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { RoutePath } from '../../global/enums/RoutePath';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { GearSvgIcon } from '../GearSvgIcon';

interface TopBarProps {
  maintainancePagePreview?: boolean;
}

const TopBar: React.FC<TopBarProps> = props => {
  const { pathname } = useLocation();
  const localization = useLocalization();
  const isSettingsPath = matchPath(`${RoutePath.SETTINGS}/*`, pathname);
  const isHomePath = matchPath(`${RoutePath.HOME}`, pathname);
  const navigate = useNavigate();
  return (
    <AppBar className="topbar">
      {isSettingsPath && !props.maintainancePagePreview ? (
        <>
          <AppBarSection>
            <GearSvgIcon
              customIcon="backPageIcon"
              className="settingsBackHome"
              onClick={() => navigate(RoutePath.HOME)}
            />
          </AppBarSection>
          <AppBarSection className="settingsTitle">
            <h1>{IntlHelper.toLangStr(localization, 'custom.settings.title')}</h1>
          </AppBarSection>
        </>
      ) : (
        <>
          <AppBarSection>
            <Logo
              className="gearLogo"
              onClick={() => (!props.maintainancePagePreview ? window.location.reload() : undefined)}
            />
          </AppBarSection>
          {isHomePath && (
            <>
              <AppBarSpacer />
              <AppBarSection>
                <GlobalSearchFilter />
              </AppBarSection>
              <AppBarSpacer />
              <AppBarSection>
                <LanguageButton />
              </AppBarSection>
              <AppBarSection>
                <SettingsButton />
              </AppBarSection>
            </>
          )}
        </>
      )}
    </AppBar>
  );
};

export default TopBar;
