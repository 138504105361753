import { load, loadMessages } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import enGbNumbers from 'cldr-numbers-full/main/en-GB/numbers.json';
import enGbLocalCurrency from 'cldr-numbers-full/main/en-GB/currencies.json';
import enGbCaGregorian from 'cldr-dates-full/main/en-GB/ca-gregorian.json';
import enGbDateFields from 'cldr-dates-full/main/en-GB/dateFields.json';

import plNumbers from 'cldr-numbers-full/main/pl/numbers.json';
import plLocalCurrency from 'cldr-numbers-full/main/pl/currencies.json';
import plCaGregorian from 'cldr-dates-full/main/pl/ca-gregorian.json';
import plDateFields from 'cldr-dates-full/main/pl/dateFields.json';

import roNumbers from 'cldr-numbers-full/main/ro/numbers.json';
import roLocalCurrency from 'cldr-numbers-full/main/ro/currencies.json';
import roCaGregorian from 'cldr-dates-full/main/ro/ca-gregorian.json';
import roDateFields from 'cldr-dates-full/main/ro/dateFields.json';
import * as enMessages from './translations/enMessages.json';
import * as plMessages from './translations/plMessages.json';
import * as roMessages from './translations/roMessages.json';

/**
 * Update imports and load method to configure Internalization and Localization for supported locales/languages
 */

const enMessagesObj = JSON.parse(JSON.stringify(enMessages));
const plMessagesObj = JSON.parse(JSON.stringify(plMessages));
const roMessageObj = JSON.parse(JSON.stringify(roMessages));

export const globalizationSetup = (): void => {
  load(
    likelySubtags,
    currencyData,
    weekData,

    enGbNumbers,
    enGbLocalCurrency,
    enGbCaGregorian,
    enGbDateFields,

    plNumbers,
    plLocalCurrency,
    plCaGregorian,
    plDateFields,

    roNumbers,
    roLocalCurrency,
    roCaGregorian,
    roDateFields
  );

  loadMessages(enMessagesObj, 'en');
  loadMessages(plMessagesObj, 'pl');
  loadMessages(roMessageObj, 'ro');
};

/**
 * Update default language if needed. By default English. If no translation for other langugages found, it will try with default english.
 */

export const defaultMessages = enMessagesObj;
