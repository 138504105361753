import * as React from 'react';
import { ViewsManagement } from './viewsManagement/ViewsManagement';
import { useLocation } from 'react-router';
import { RoutePath } from '../../global/enums/RoutePath';
import { HelpCenterManagement } from './helpCenterManagement/HelpCenterManagement';
import { AdminCenterManagement } from './adminCenterManagement/AdminCenterManagement';
import { GearUiAdminConfigWriteType } from '../../Model/uiAdminConfig/GearUiAdminConfigWriteType';

interface SettingsContentProps {
  uiAdminConfigWritePermissions: GearUiAdminConfigWriteType;
}

export const SettingsContent: React.FC<SettingsContentProps> = props => {
  const { pathname } = useLocation();

  switch (pathname) {
    case `/${RoutePath.SETTINGS}/${RoutePath.SETTINGS_VIEWS}`:
      return <ViewsManagement />;
    case `/${RoutePath.SETTINGS}/${RoutePath.SETTINGS_HELP_CENTER}`:
      return <HelpCenterManagement />;
    case `/${RoutePath.SETTINGS}/${RoutePath.SETTINGS_ADMIN_CENTER}`:
      return <AdminCenterManagement uiAdminConfigWritePermissions={props.uiAdminConfigWritePermissions} />;
    default:
      return <></>;
  }
};
