import {
  Grid,
  GridCellProps,
  GridColumn,
  GridHeaderCellProps,
  GridNoRecords,
  GridRowProps
} from '@progress/kendo-react-grid';
import * as React from 'react';
import { SETTINGS_VIEW_FIELD, SettingsViewDataItem } from './SettingsViewDataItem';
import { useLocalization } from '@progress/kendo-react-intl';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { LoadingGridPanel } from '../../grid/LoadingGridPanel';
import HiddenHeader from '../../grid/HiddenHeader';
import { DragHandleCell } from './privateView/DragHandleCell';
import { DragAndDrop } from '@progress/kendo-react-common';
import { useEffect, useRef, useState } from 'react';
import { ElementDimensions, useWindowDimensions } from '../../../global/hooks/useWindowDimensions';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { HeaderCell } from '../../grid/customCells/HeaderCell';

interface ViewGridProps {
  type: 'DEFAULT_VIEW' | 'PRIVATE_VIEW';
  data: SettingsViewDataItem[];
  cellRender: (
    tdElement: React.ReactElement<HTMLTableCellElement> | null,
    cellProps: GridCellProps
  ) => JSX.Element | null;
  rowRender?: (
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    row: React.ReactElement<HTMLTableRowElement, string | React.JSXElementConstructor<any>>,
    props: GridRowProps
  ) => React.ReactNode;
  isLoading: boolean;
}

export const ViewGrid: React.FC<ViewGridProps> = props => {
  const localization = useLocalization();
  const ref = useRef<HTMLDivElement>(null);
  const [gridDimensions, setGridDimensions] = useState<ElementDimensions>({ width: 0, height: 0 });
  const windowDimensions = useWindowDimensions();
  useEffect(() => {
    if (ref && ref.current) {
      const paddingSize = 50;
      setGridDimensions({
        height: document.body.offsetHeight - ref.current.getBoundingClientRect().top - paddingSize,
        width: ref.current.getBoundingClientRect().width
      });
    }
  }, [windowDimensions]);

  const headerRender = (tdElement: React.ReactNode, headerProps: GridHeaderCellProps) => {
    return <HeaderCell compositeFilterDescriptor={undefined} headerProps={headerProps} tdElement={tdElement} />;
  };

  const gridContent: JSX.Element = (
    <>
      <Tooltip openDelay={500} anchorElement="target" parentTitle={true} position="left">
        <Grid
          data={props.data}
          dataItemKey={'ProductID'}
          cellRender={props.cellRender}
          rowRender={props.rowRender}
          headerCellRender={headerRender}
          style={{
            ...(props.isLoading && { minHeight: 500 }),
            height: gridDimensions.height,
            width: gridDimensions.width
          }}
        >
          <GridNoRecords>{IntlHelper.toLangStr(localization, 'custom.common.emptyGridMessage')}</GridNoRecords>
          {props.type === 'PRIVATE_VIEW' && (
            <GridColumn
              title={IntlHelper.toLangStr(localization, 'custom.settings.views.gridHeader.order')}
              cell={DragHandleCell}
            />
          )}
          <GridColumn
            field={SETTINGS_VIEW_FIELD.NAME}
            title={IntlHelper.toLangStr(localization, 'custom.settings.views.gridHeader.name')}
          />
          <GridColumn
            field={SETTINGS_VIEW_FIELD.WIDTH}
            title={IntlHelper.toLangStr(localization, 'custom.settings.views.gridHeader.width')}
          />
          <GridColumn
            field={SETTINGS_VIEW_FIELD.LINK}
            title={IntlHelper.toLangStr(localization, 'custom.settings.views.gridHeader.isLink')}
          />
          {props.type === 'PRIVATE_VIEW' && (
            <GridColumn field={SETTINGS_VIEW_FIELD.REMOVE} width={80} headerCell={HiddenHeader} />
          )}
        </Grid>
      </Tooltip>
      {props.isLoading && <LoadingGridPanel />}
    </>
  );

  return <div ref={ref}>{props.type === 'PRIVATE_VIEW' ? <DragAndDrop>{gridContent}</DragAndDrop> : gridContent}</div>;
};
