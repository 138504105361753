import { useLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { IntlHelper } from '../../helpers/IntlHelper';

interface LoadingGridPanelProps {}

export const LoadingGridPanel: React.FC<LoadingGridPanelProps> = () => {
  const localization = useLocalization();
  return (
    <div className="k-loading-mask">
      <span className="k-loading-text">{IntlHelper.toLangStr(localization, 'custom.common.loadingText')}</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
};
