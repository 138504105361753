import * as React from 'react';
import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from '@tanstack/react-query';
import { appInsights } from '../context/applicationInsights/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useNotificationContext } from '../global/hooks/useNotificationContext';
import { useMemo } from 'react';

interface QueryProviderWrapperProps {
  children: React.ReactNode;
}

export const QueryProviderWrapper: React.FC<QueryProviderWrapperProps> = ({ children }) => {
  const { showNotification } = useNotificationContext();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 2000
          }
        },
        queryCache: new QueryCache({
          onError: (error, query) => {
            if (query.state.error) {
              if (error instanceof Error) {
                const errorMessage = `Something went wrong: ${error.message}`;
                showNotification({ type: 'error', message: errorMessage });
                appInsights.trackException({
                  error: new Error(`FRONTEND: ${errorMessage}`),
                  severityLevel: SeverityLevel.Error
                });
              } else {
                const errorMessage = `Something went wrong for query: ${query.queryKey}`;
                showNotification({ type: 'error', message: errorMessage });
                appInsights.trackException({
                  error: new Error(`FRONTEND: ${errorMessage}`),
                  severityLevel: SeverityLevel.Error
                });
              }
            }
          }
        }),
        mutationCache: new MutationCache({
          onError: (error, _variables, _context, mutation) => {
            if (mutation.options.onError) {
              return;
            }
            if (error instanceof Error) {
              const errorMessage = `Something went wrong: ${error.message}`;
              showNotification({ type: 'error', message: errorMessage });
              appInsights.trackException({
                error: new Error(`FRONTEND: ${errorMessage}`),
                severityLevel: SeverityLevel.Error
              });
            } else {
              const errorMessage = `Something went wrong: ${error}`;
              appInsights.trackException({
                error: new Error(`FRONTEND: ${errorMessage}`),
                severityLevel: SeverityLevel.Error
              });
            }
          }
        })
      }),
    []
  );
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
