import { AccountInfo, BrowserCacheLocation, Configuration, PopupRequest, SilentRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_REGISTRATION_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage
  }
};

export const loginRequest: PopupRequest = {
  scopes: ['User.Read']
};

export const getSilentRequest = (account: AccountInfo): SilentRequest => ({
  ...loginRequest,
  account,
  refreshTokenExpirationOffsetSeconds: 86400 // 24 hours * 60 minutes * 60 seconds = 86400 seconds
});
