import * as React from 'react';
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';
import { useEffect, useRef, useState } from 'react';
import { ElementDimensions, useWindowDimensions } from '../../../global/hooks/useWindowDimensions';
import {
  GEAR_HELP_CENTER_MENU_ITEM_TYPE,
  GearHelpCenterMenuItemType
} from '../../../global/enums/GearHelpCenterMenuItemType';
import { Button } from '@progress/kendo-react-buttons';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { REPORT_ISSUE_EMAIL, REPORT_ISSUE_EMAIL_BODY, REPORT_ISSUE_EMAIL_SUBJECT } from '../../../config/consts';

interface HelpCenterDocumentProps {
  selectedMenuItem: GearHelpCenterMenuItemType;
}

export const HelpCenterDocument: React.FC<HelpCenterDocumentProps> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const windowDimensions = useWindowDimensions();
  const [helpCenterDimensions, setHelpCenterDimensions] = useState<ElementDimensions>({ width: 0, height: 0 });
  const localization = useLocalization();
  useEffect(() => {
    if (ref && ref.current) {
      const paddingSize = 50;
      setHelpCenterDimensions({
        height: document.body.offsetHeight - ref.current.getBoundingClientRect().top - paddingSize,
        width: ref.current.getBoundingClientRect().width
      });
    }
  }, [windowDimensions]);

  const docUrl = React.useMemo(() => {
    switch (props.selectedMenuItem) {
      case GEAR_HELP_CENTER_MENU_ITEM_TYPE.USER_MANUAL:
        return 'User%27s Manual';
      case GEAR_HELP_CENTER_MENU_ITEM_TYPE.ADMIN_MANUAL:
        return 'Admin%27s Manual';
      case GEAR_HELP_CENTER_MENU_ITEM_TYPE.FAQ:
        return 'FAQ';
      default:
        return 'User%27s Manual';
    }
  }, [props.selectedMenuItem]);

  return (
    <div ref={ref} className="helpCeneterContent">
      {props.selectedMenuItem === GEAR_HELP_CENTER_MENU_ITEM_TYPE.RAPORT_ISSUE ? (
        <div>
          <Button
            themeColor="dark"
            onClick={() =>
              window.open(
                `mailto:${REPORT_ISSUE_EMAIL}?subject=${REPORT_ISSUE_EMAIL_SUBJECT}&body=${REPORT_ISSUE_EMAIL_BODY}`
              )
            }
          >
            {IntlHelper.toLangStr(localization, 'custom.settings.helpCenter.reportIssue.title')}
          </Button>
          <br />
          <h3>
            {IntlHelper.toLangStr(localization, 'custom.settings.helpCenter.reportIssue.description')}
            <br />
            <ul>
              <li>Romania: Alin Vija – alin.vija@volvo.com</li>
              <li>Poland: Bartłomiej Szuba - bartlomiej.szuba@volvo.com</li>
            </ul>
          </h3>
        </div>
      ) : (
        <PDFViewer url={`/documents/${docUrl}.pdf`} style={{ height: helpCenterDimensions.height }} />
      )}
    </div>
  );
};
