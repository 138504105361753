import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { currentUserKeys } from './queryKeyFactory';
import { useContext } from 'react';
import ServicesContext from '../../global/services/ServicesContext';
import { GearUser } from '../../Model/GearUser';
import { Nullable } from '../../Model/CommonProps';

export function useGetCurrentUser(): UseQueryResult<Nullable<GearUser>, unknown> {
  const servicesCtx = useContext(ServicesContext);

  const getCurrentUser = async () => {
    if (servicesCtx?.currentUserService) {
      const response = await servicesCtx.currentUserService.getCurrentUser();
      if (!response) {
        return null;
      }
      const currentUser: GearUser = {
        id: response.id,
        email: response.email,
        displayName: response.displayName,
        indexes: response.indexes
      };
      return currentUser;
    }
    throw new Error('Services context not initialized');
  };

  return useQuery({
    queryKey: currentUserKeys.me,
    queryFn: getCurrentUser,
    retry: 0,
    staleTime: Infinity,
    cacheTime: 28800000
  });
}
