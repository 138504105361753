import { ChipList, ChipListChangeEvent } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { GearSettingMenuItem } from '../../../Model/GearSettingMenuItem';
import { GearHelpCenterMenuItemType } from '../../../global/enums/GearHelpCenterMenuItemType';

interface HelpCenterMenuProps {
  menu: GearSettingMenuItem[];
  value: GearHelpCenterMenuItemType;
  onSelectedMenuItemChanged: (value: GearHelpCenterMenuItemType) => void;
}

export const HelpCenterMenu: React.FC<HelpCenterMenuProps> = props => {
  const handleChange = (event: ChipListChangeEvent): void => {
    props.onSelectedMenuItemChanged(event.value);
  };

  return (
    <ChipList
      className="helpCenterMenu"
      defaultData={props.menu}
      selection="single"
      value={props.value}
      onChange={handleChange}
    />
  );
};
