import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { GearGridCell } from './GearGridCell';
import { useLocalization } from '@progress/kendo-react-intl';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { GearSvgIcon } from '../../GearSvgIcon';

interface RemoveCellProps extends GridCellProps {
  onClick: () => void;
}

export const RemoveCell: React.FC<RemoveCellProps> = props => {
  const localization = useLocalization();
  return (
    <GearGridCell
      {...props}
      customStyle={{ padding: 0 }}
      title={IntlHelper.toLangStr(localization, 'custom.common.remove')}
    >
      <GearSvgIcon customIcon="removeIcon" className="removePrivateViewFieldIcon" onClick={props.onClick} />
    </GearGridCell>
  );
};
