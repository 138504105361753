import { InputPrefix, InputSuffix, TextBox } from '@progress/kendo-react-inputs';
import * as React from 'react';
import { useSearchContext } from '../../../../context/search/SearchContext';
import { useViewsContext } from '../../../../context/view/ViewsContext';
import { SvgIcon } from '@progress/kendo-react-common';
import { searchIcon, arrowLeftIcon } from '@progress/kendo-svg-icons';
import { EMPTY_VALUE } from '../../../../config/consts';
import { useState } from 'react';
import { GEAR_FIELD_TYPE } from '../../../../global/enums/GearFieldType';
import { IntlHelper } from '../../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { useDocumentsContext } from '../../../../context/documents/DocumentsContext';

export const GlobalSearchFilter: React.FC = () => {
  const { queryRequest, setQueryRequest } = useSearchContext();
  const { setSelectedDocuments } = useDocumentsContext();
  const { defaultUserView } = useViewsContext();
  const [tempSearchPhrase, setTempSearchPhrase] = useState<string>(EMPTY_VALUE);
  const localization = useLocalization();
  const handleSearch = (newSearchPhrase: string) => {
    if (!queryRequest || !setQueryRequest || !defaultUserView?.fields) {
      return;
    }
    setQueryRequest({
      ...queryRequest,
      searchFields: defaultUserView.fields
        .filter(field => field.type !== GEAR_FIELD_TYPE.DATE)
        .map(field => field.name),
      searchText: newSearchPhrase !== EMPTY_VALUE ? newSearchPhrase : undefined
    });
    setTempSearchPhrase(newSearchPhrase);
    if (setSelectedDocuments) {
      setSelectedDocuments([]);
    }
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      handleSearch(tempSearchPhrase);
    }
  };
  return (
    <>
      {queryRequest && (
        <TextBox
          id={'global-search-filter'}
          className="globalSearchFilter"
          placeholder={IntlHelper.toLangStr(localization, 'custom.topBar.globalSearch')}
          defaultValue={queryRequest.searchText}
          value={tempSearchPhrase}
          onChange={e => setTempSearchPhrase(e.target.value !== undefined ? e.target.value?.toString() : '')}
          onKeyDown={handleKeyPress}
          fillMode={'outline'}
          autoComplete="off"
          prefix={() => (
            <>
              <InputPrefix>
                <SvgIcon
                  className={tempSearchPhrase !== EMPTY_VALUE ? `globalSearchFilter--reset` : ''}
                  icon={tempSearchPhrase !== EMPTY_VALUE ? arrowLeftIcon : searchIcon}
                  onClick={() => handleSearch(EMPTY_VALUE)}
                />
              </InputPrefix>
            </>
          )}
          suffix={() => (
            <>
              {tempSearchPhrase !== EMPTY_VALUE && (
                <>
                  <InputSuffix>
                    <SvgIcon
                      className="globalSearchFilter--search"
                      enableBackground={1}
                      icon={searchIcon}
                      onClick={() => handleSearch(tempSearchPhrase)}
                    />
                  </InputSuffix>
                </>
              )}
            </>
          )}
        />
      )}
    </>
  );
};
