import { createContext, useContext } from 'react';
import { GearField } from '../../Model/GearField';

interface IFieldsContext {
  data?: GearField[];
}

export const FieldsContext = createContext<IFieldsContext>({
  data: []
});

export const useFieldsContext = () => useContext(FieldsContext);
