import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING;
const instrumentationKey = process.env.REACT_APP_INSTRUMENTATION_KEY;
const appInsights = new ApplicationInsights({
  config: {
    connectionString,
    instrumentationKey,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
