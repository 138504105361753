import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { viewsKeys } from './queryKeyFactory';
import { useContext } from 'react';
import { GearView } from '../../Model/GearView';
import ServicesContext from '../../global/services/ServicesContext';

export function useGetDefaultView(
  privIfExists: boolean,
  locale?: string,
  indexId?: number
): UseQueryResult<GearView, unknown> {
  const servicesCtx = useContext(ServicesContext);

  const getDefaultView = () => {
    if (servicesCtx?.viewsService) {
      return servicesCtx.viewsService.getDefaultView(privIfExists, locale, indexId);
    }
    throw new Error('Services context not initialized');
  };

  return useQuery({
    queryKey: viewsKeys.defaultViewByLocale(privIfExists, locale, indexId),
    queryFn: getDefaultView,
    retry: 0,
    staleTime: 180000,
    cacheTime: 180000
  });
}
