import * as React from 'react';
import { Link } from 'react-router-dom';
import { RoutePath } from '../global/enums/RoutePath';
import { Typography } from '@progress/kendo-react-common';
import GearPage from './GearPage';
import { useLocalization } from '@progress/kendo-react-intl';
import { IntlHelper } from '../helpers/IntlHelper';

const NotExistingPage: React.FC = () => {
  const localization = useLocalization();
  return (
    <GearPage>
      <Typography.h2>{IntlHelper.toLangStr(localization, 'custom.noMatchPage.title')}</Typography.h2>
      <Typography.p>
        <Link to={RoutePath.HOME}>{IntlHelper.toLangStr(localization, 'custom.noMatchPage.description')}</Link>
      </Typography.p>
    </GearPage>
  );
};

export default NotExistingPage;
