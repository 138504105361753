import { createContext, useContext } from 'react';
import { GearLocale } from '../../Model/GearLocale';
import { DEFAULT_LOCALE } from '../../config/consts';
import { GearDictionary } from '../../Model/GearDictionary';

interface IGlobalizationContext {
  availableLocales?: GearDictionary[];
  selectedLocale: GearLocale;
  setSelectedLocale?: (newLocale: GearLocale) => void;
}

const defaultValue: IGlobalizationContext = {
  selectedLocale: DEFAULT_LOCALE
};

export const GlobalizationContext = createContext<IGlobalizationContext>(defaultValue);

export const useGlobalizationContext = () => useContext(GlobalizationContext);
