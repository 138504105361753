import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Hint, Label, Error } from '@progress/kendo-react-labels';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import * as React from 'react';
import { useRef } from 'react';

export interface GearFormWrapperProps extends FieldRenderProps {
  children: React.ReactNode;
  tooltipMessage?: string;
  isVertical?: boolean;
}

export const GearFieldWrapper: React.FC<GearFormWrapperProps> = props => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    label,
    wrapperStyle,
    optional,
    modified,
    visited,
    tooltipMessage,
    isVertical
  } = props;
  const showValidationMessage: string | false | null = (touched || modified || visited) && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';
  const editorRef = useRef<HTMLDivElement>(null);
  const errorLabelClass = showValidationMessage ? 'k-text-error' : '';
  const labelId: string = label ? `${id}_label` : '';
  return (
    <FieldWrapper style={wrapperStyle}>
      <GridLayout align={{ vertical: 'middle' }} className={label ? 'gearFieldWrapper' : ''}>
        {label && (
          <GridLayoutItem row={1} col={1}>
            <div title={tooltipMessage}>
              <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}
                className={`k-form-label ${errorLabelClass} ${
                  optional || optional === undefined ? 'optional' : 'required'
                }`}
              >
                {label}
              </Label>
            </div>
          </GridLayoutItem>
        )}
        <GridLayoutItem row={isVertical ? 2 : 1} col={isVertical ? 1 : 2}>
          <div className={'k-form-field-wrap'} ref={editorRef}>
            {props.children}
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
          </div>
        </GridLayoutItem>
      </GridLayout>
    </FieldWrapper>
  );
};
