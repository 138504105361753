import { GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';
import * as React from 'react';
import { EMPTY_VALUE } from '../../../config/consts';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useInternationalization } from '@progress/kendo-react-intl';
import { GearGridCell } from './GearGridCell';
export interface EditableNumericTextBoxCellProps extends GridCellProps {
  onItemChange?: (event: GridItemChangeEvent) => void;
  inEdit?: boolean;
}

export const EditableNumericTextBoxCell: React.FC<EditableNumericTextBoxCellProps> = props => {
  const field = props.field || EMPTY_VALUE;
  const value = props.dataItem[field] as number;
  const intl = useInternationalization();

  const handleChange = (event: NumericTextBoxChangeEvent): void => {
    if (!props.onItemChange) {
      return;
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const newEvent: any = {
      ...event,
      dataItem: props.dataItem,
      field: field
    };
    props.onItemChange(newEvent);
  };

  const formattedValue = isNaN(value) ? value : IntlHelper.formatNumber(intl, value, 'n2');

  return (
    <GearGridCell {...props} customClassName="editableNumericTextBoxCell" title={isNaN(value) ? '' : value?.toString()}>
      {props.inEdit === false ? (
        <>{formattedValue}</>
      ) : (
        <NumericTextBox format="percent" step={50} min={0} max={1000} value={value} onChange={handleChange} />
      )}
    </GearGridCell>
  );
};
