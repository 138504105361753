import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { GearConfirmDialogState } from '../../Model/GearConfirmDialogState';

interface IConfirmDialogProps {
  title?: string;
  message: string;
  onConfirm: (confirmed: GearConfirmDialogState) => void;
  confirmButtonOnly?: boolean;
  className?: string;
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = props => {
  const localization = useLocalization();
  return (
    <Dialog className={props.className || ''} title={props.title} onClose={() => props.onConfirm('dismiss')}>
      <p>{props.message}</p>
      <DialogActionsBar>
        {props.confirmButtonOnly ? (
          <Button
            themeColor="primary"
            title={IntlHelper.toLangStr(localization, 'custom.common.confirm')}
            onClick={() => props.onConfirm('yes')}
          >
            {IntlHelper.toLangStr(localization, 'custom.common.confirm')}
          </Button>
        ) : (
          <>
            <Button
              themeColor="primary"
              title={IntlHelper.toLangStr(localization, 'custom.common.yes')}
              onClick={() => props.onConfirm('yes')}
            >
              {IntlHelper.toLangStr(localization, 'custom.common.yes')}
            </Button>
            <Button
              themeColor="secondary"
              title={IntlHelper.toLangStr(localization, 'custom.common.no')}
              onClick={() => props.onConfirm('no')}
            >
              {IntlHelper.toLangStr(localization, 'custom.common.no')}
            </Button>
          </>
        )}
      </DialogActionsBar>
    </Dialog>
  );
};
