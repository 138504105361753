import { MultiViewCalendar, MultiViewCalendarProps } from '@progress/kendo-react-dateinputs';
import * as React from 'react';
import { GlobalFilterState } from '../GlobalFilterState';
import { RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import { DateTimeFieldPicker } from './DateTimeFieldPicker';

interface DatePickerCalendarProps extends MultiViewCalendarProps {
  dateFiltersState: GlobalFilterState[];
  fieldSelected: GlobalFilterState;
  handleFieldChange: (event: RadioButtonChangeEvent) => void;
}

export const DatePickerCalendar: React.FC<DatePickerCalendarProps> = props => {
  return (
    <div className="dateTimeCalendar">
      <DateTimeFieldPicker {...props} />
      <MultiViewCalendar {...props} />
    </div>
  );
};
