import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { uiAdminConfigsKeys } from './queryKeyFactory';
import { useContext } from 'react';
import ServicesContext from '../../global/services/ServicesContext';
import { GearUiAdminConfigType } from '../../Model/uiAdminConfig/GearUiAdminConfigType';

export function useGetUiAdminConfigEntry(key?: GearUiAdminConfigType): UseQueryResult<string, unknown> {
  const servicesCtx = useContext(ServicesContext);

  const getUiAdminConfigEntry = () => {
    if (servicesCtx?.uiAdminConfigsService && key) {
      return servicesCtx.uiAdminConfigsService.getUiAdminConfigEntry(key);
    }
    throw new Error('Services context not initialized');
  };

  return useQuery({
    queryKey: uiAdminConfigsKeys.uiAdminConfigByKey(key),
    queryFn: getUiAdminConfigEntry,
    enabled: !!key,
    retry: 0,
    staleTime: Infinity,
    cacheTime: 28800000
  });
}
