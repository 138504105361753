import { GEAR_LOCALE } from '../Model/GearLocale';

export const API_URL =
  (window.location.origin.includes('azurefd.net') || window.location.origin.includes('volvo.com')
    ? window.location.origin
    : process.env.REACT_APP_API_URL) + '/api';

export const SPO_MANUALS_URL =
  'https://volvogroup.sharepoint.com/sites/proj-foundational-product/Shared Documents/General/Manuals'; // TO BE stored in BFF db

export const REPORT_ISSUE_EMAIL = 'support.sphotel@volvo.com';
export const REPORT_ISSUE_EMAIL_SUBJECT = 'GEAR - issue report';
export const REPORT_ISSUE_EMAIL_BODY = `Dear Support Team,%0D%0A%0D%0A
 
I am writing to report an issue I encountered while using GEAR application.%0D%0A%0D%0A
 
Description of the issue:%0D%0A%0D%0A
 
%%Please replace this text with a detailed description of the issue you are experiencing. Include any error messages, steps to reproduce the problem, and any other relevant information. If applicable, attach any screenshots or files that may help illustrate the issue.%% %0D%0A%0D%0A
 
I appreciate your attention to this matter. Please let me know if you require any further information. %0D%0A%0D%0A
 
Thank you for your assistance.%0D%0A%0D%0A
 
Best regards,%0D%0A
%%Name%%`;

export const DEFAULT_ROWS_PER_PAGE = 100;
export const DEFAULT_COLUMN_WIDTH = 200;
export const MIN_COLUMN_WIDTH = 50;
export const MAX_COLUMN_WIDTH = 1000;
export const COLUMN_WIDTH_STEP = 50;
export const GRID_PAGE_SIZES = [100, 200, 300];

export const EMPTY_VALUE = '';

export const SELECTED_FIELD = 'selected';
export const NOTE_FIELD = 'note';
export const EXTENSION_FIELD = 'Cps_Extension';
export const DOC_ID_FIELD = 'Cps_DocId';
export const CONTENT_TYPE_FIELD = 'Cps_ContentType';

export const USER_LOCALE_SETTINGS_KEY = 'GearUserLocale';
export const USER_INDEX_SETTINGS_KEY = 'GearUserIndex';
export const DEFAULT_LOCALE = GEAR_LOCALE.enGB;
export const MAX_ITEMS_LIMIT_EXPORT_CSV = 100000;
export const TOO_MANY_REQUESTS_EXPORT_CSV = 30; // in seconds

export const RICH_TEXT_CHARACTER_LIMIT = 63999;
