import * as React from 'react';
import { DropDownButton, DropDownButtonItem, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { Message } from '../intl/Message';
import { GearLanguage } from '../../context/globalization/GlobalizationProvider';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { globeIcon } from '@progress/kendo-svg-icons';
import { GearLocale } from '../../Model/GearLocale';
import { useGlobalizationContext } from '../../context/globalization/GlobalizationContext';

const LanguageButton: React.FC = () => {
  const { selectedLocale, setSelectedLocale } = useGlobalizationContext();
  const localization = useLocalization();
  const { availableLocales } = useGlobalizationContext();

  const languagesDictionary: GearLanguage[] | undefined = availableLocales?.map(availableLocale => ({
    locale: availableLocale.name as GearLocale,
    text: IntlHelper.toLangStr(localization, `custom.topBar.langugage.${availableLocale.name}`)
  }));

  return (
    <DropDownButton
      text={<Message messageKey={'custom.topBar.langugage.title'} />}
      svgIcon={globeIcon}
      onItemClick={(event: DropDownButtonItemClickEvent) => {
        const selectedLanguage = languagesDictionary?.find(i => i.text === event.item.text);
        if (setSelectedLocale && selectedLanguage) {
          setSelectedLocale(selectedLanguage.locale);
        }
      }}
      themeColor={'primary'}
    >
      {languagesDictionary?.map((language, index) => {
        return (
          <DropDownButtonItem
            key={index}
            text={language.text}
            icon={language.icon}
            selected={language.locale === selectedLocale}
          />
        );
      })}
    </DropDownButton>
  );
};

export default LanguageButton;
