import * as React from 'react';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { Switch } from '@progress/kendo-react-inputs';
import { GearFieldWrapper } from '../GearFieldWrapper';
import { useLocalization } from '@progress/kendo-react-intl';
import { IntlHelper } from '../../../../helpers/IntlHelper';

export const FormSwitcher: React.FC<FieldRenderProps> = fieldRenderProps => {
  const localization = useLocalization();
  return (
    <GearFieldWrapper {...fieldRenderProps}>
      <Switch
        {...fieldRenderProps}
        onLabel={IntlHelper.toLangStr(localization, 'custom.common.enable')}
        offLabel={IntlHelper.toLangStr(localization, 'custom.common.disable')}
        validationMessage={fieldRenderProps.validationMessage || undefined}
        checked={fieldRenderProps.value}
      />
    </GearFieldWrapper>
  );
};
