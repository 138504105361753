import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { EMPTY_VALUE } from '../../../config/consts';
import { GearGridCell } from './GearGridCell';
import { useInternationalization } from '@progress/kendo-react-intl';

const DateTimeCell: React.FC<GridCellProps> = props => {
  const field = props.field || EMPTY_VALUE;
  const value = props.dataItem[field];
  const intl = useInternationalization();
  return (
    <GearGridCell {...props} title={intl.formatDate(new Date(value), { date: 'full', datetime: 'medium' })}>
      {!value ? EMPTY_VALUE : <span>{intl.formatDate(new Date(value), { datetime: 'short' })}</span>}
    </GearGridCell>
  );
};

export default DateTimeCell;
