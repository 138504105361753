import React, { useMemo } from 'react';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useInternationalization, useLocalization } from '@progress/kendo-react-intl';
import { useSearchContext } from '../../../context/search/SearchContext';

const SearchResultsCount: React.FC = () => {
  const localization = useLocalization();
  const intl = useInternationalization();
  const { data } = useSearchContext();
  const resultsCount = useMemo(() => {
    if (!data) {
      return 0;
    }
    return data.totalCount;
  }, [data]);
  return (
    <div>
      {intl.formatNumber(resultsCount, '0')} {IntlHelper.toLangStr(localization, 'custom.toolbar.searchResults')}
    </div>
  );
};

export default SearchResultsCount;
