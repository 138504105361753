import * as React from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { GlobalizationContext } from './GlobalizationContext';
import { ReactNode, useCallback, useState } from 'react';
import { GEAR_LOCALE, GearLocale } from '../../Model/GearLocale';
import { DEFAULT_LOCALE, USER_LOCALE_SETTINGS_KEY } from '../../config/consts';
import { useGetLocales } from './useGetLocales';

interface GlobalizationProviderProps {
  children: ReactNode;
}

export interface GearLanguage {
  locale: GearLocale;
  text: string;
  icon?: string;
}

const getLanguageFromLocale = (locale: GearLocale) => {
  return locale.split(/[_-]/)[0];
};

const setLocaleSettingsKey = (locale: string) => {
  window.localStorage.setItem(USER_LOCALE_SETTINGS_KEY, locale);
};

const getLocaleSettingsKey = () => {
  return window.localStorage.getItem(USER_LOCALE_SETTINGS_KEY);
};

const getSupportedLocale = (localLocale: string) => {
  if (Object.values(GEAR_LOCALE).includes(localLocale as GearLocale)) {
    return localLocale as GearLocale;
  } else {
    return DEFAULT_LOCALE;
  }
};

const getUserLocale = () => {
  const storedLocale = getLocaleSettingsKey();
  if (!storedLocale) {
    const browserLocale = navigator.language;
    return getSupportedLocale(browserLocale);
  }
  return getSupportedLocale(storedLocale);
};

const GlobalizationProvider: React.FC<GlobalizationProviderProps> = props => {
  const { data: availableLocales } = useGetLocales();
  const [selectedLocale, setSelectedLocale] = useState<GearLocale>(DEFAULT_LOCALE);

  React.useEffect(() => {
    const userLocale = getUserLocale();
    setSelectedLocale(userLocale);
  }, []);

  const handleSetLocale = useCallback((newLocale: GearLocale) => {
    const userLocale = getSupportedLocale(newLocale);
    setLocaleSettingsKey(userLocale);
    setSelectedLocale(userLocale);
  }, []);

  return (
    <GlobalizationContext.Provider
      value={{
        availableLocales,
        selectedLocale,
        setSelectedLocale: handleSetLocale
      }}
    >
      <IntlProvider locale={selectedLocale}>
        <LocalizationProvider language={getLanguageFromLocale(selectedLocale)}>{props.children}</LocalizationProvider>
      </IntlProvider>
    </GlobalizationContext.Provider>
  );
};

export default GlobalizationProvider;
