export enum RoutePath {
  HOME = '/',
  SETTINGS = 'ui/settings',
  SETTINGS_VIEWS = 'views',
  SETTINGS_HELP_CENTER = 'help-center',
  SETTINGS_ADMIN_CENTER = 'admin-center',
  LOGOUT = 'ui/logout',
  ACCESS_DENIED = 'ui/access-denied',
  MAINTENANCE = 'ui/maintenance'
}
