import * as React from 'react';
import { TextBox } from '@progress/kendo-react-inputs';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { GearFieldWrapper } from '../GearFieldWrapper';

export const FormTextBox: React.FC<FieldRenderProps> = fieldRenderProps => {
  return (
    <GearFieldWrapper {...fieldRenderProps}>
      <TextBox
        onChange={fieldRenderProps.onChange}
        value={fieldRenderProps.value}
        name={fieldRenderProps.name}
        valid={fieldRenderProps.valid}
      />
    </GearFieldWrapper>
  );
};
