import { createContext, useContext } from 'react';
import { GearSelectedDocument } from '../../Model/GearSelectedDocument';

interface IDocumentsContext {
  selectedDocuments: GearSelectedDocument[];
  setSelectedDocuments?: (selectedDocuments: GearSelectedDocument[]) => void;
  openDocument?: (contentType: string, documentId: string) => void;
  downloadFiles?: () => void;
  openDocumentLoading: boolean;
  downlaodFilesLoading: boolean;
}

const defaultValue: IDocumentsContext = {
  selectedDocuments: [],
  openDocumentLoading: false,
  downlaodFilesLoading: false
};

export const DocumentsContext = createContext<IDocumentsContext>(defaultValue);

export const useDocumentsContext = () => useContext(DocumentsContext);
