import { Loader, LoaderType } from '@progress/kendo-react-indicators';
import * as React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';

interface GearLoaderProps {
  type?: LoaderType;
  textMessageKey?: string;
}

const GearLoader: React.FC<GearLoaderProps> = props => {
  const localization = useLocalization();
  return (
    <Dialog className={`gearLoader ${!props.textMessageKey ? 'gearLoader--no-background' : ''}`}>
      <Loader size="large" type={props.type || 'converging-spinner'} />
      {props.textMessageKey && (
        <div className="gearLoaderText">{IntlHelper.toLangStr(localization, props.textMessageKey)}</div>
      )}
    </Dialog>
  );
};

export default GearLoader;
