import * as React from 'react';
import { Route, Routes } from 'react-router';
import { RoutePath } from '../global/enums/RoutePath';
import MainPage from '../pages/MainPage';
import SettingsPage from '../pages/SettingsPage';
import NoMatchPage from '../pages/NoMatchPage';
import LogoutPage from '../pages/LogoutPage';
import AccessDeniedPage from '../pages/AccessDeniedPage';
import { useCurrentUserContext } from '../context/currentUser/CurrentUserContext';
import GearLoader from './common/GearLoader';
import { useUiAdminConfigsContext } from '../context/uiAdminConfigs/UiAdminConfigsContext';
import MaintenancePage from '../pages/MaintenancePage';
import { GEAR_UI_ADMIN_CONFIG_TYPE } from '../Model/uiAdminConfig/GearUiAdminConfigType';
import { GearMessageBar } from './common/GearMessageBar';

const Root: React.FC = () => {
  const { currentUser, isLoading } = useCurrentUserContext();
  const { uiAdminConfigState, uiAdminConfigWritePermissions, setConfigWritePermissionsKeys } =
    useUiAdminConfigsContext();

  React.useEffect(() => {
    if (setConfigWritePermissionsKeys) {
      setConfigWritePermissionsKeys([GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE]);
    }
  }, [setConfigWritePermissionsKeys]);

  if (isLoading || !uiAdminConfigWritePermissions || !uiAdminConfigState.maintenancePage) {
    return <GearLoader />;
  }

  if (!currentUser) {
    return (
      <Routes>
        <Route path="*" element={<AccessDeniedPage />} />
      </Routes>
    );
  }

  if (!uiAdminConfigWritePermissions.maintenancePage && uiAdminConfigState.maintenancePage?.enabled) {
    return (
      <Routes>
        <Route path="*" element={<MaintenancePage />} />
      </Routes>
    );
  }

  return (
    <>
      <GearMessageBar />
      <Routes>
        <Route path={RoutePath.HOME} element={<MainPage />} />
        <Route path={RoutePath.SETTINGS} element={<SettingsPage />}>
          <Route path={RoutePath.SETTINGS_VIEWS} element={<SettingsPage />} />
          <Route path={RoutePath.SETTINGS_HELP_CENTER} element={<SettingsPage />} />
          <Route path={RoutePath.SETTINGS_ADMIN_CENTER} element={<SettingsPage />} />
        </Route>
        <Route path={RoutePath.LOGOUT} element={<LogoutPage />} />
        <Route path={RoutePath.MAINTENANCE} element={<MaintenancePage />} />
        <Route path="*" element={<NoMatchPage />} />
      </Routes>
    </>
  );
};

export default Root;
