import { IntlService, LocalizationService, NumberFormatOptions } from '@progress/kendo-react-intl';
import { defaultMessages } from '../config/globalization/globalizationSetup';
import * as _ from 'lodash';

export class IntlHelper {
  public static toLangStr = (localization: LocalizationService, messageKey: string) => {
    return localization.toLanguageString(messageKey, _.get(defaultMessages, messageKey) || `${messageKey}`);
  };

  public static formatDate = (intl: IntlService, date: Date) => {
    return intl.formatDate(date);
  };

  public static formatNumber = (intl: IntlService, number: number, format: NumberFormatOptions | string) => {
    return intl.formatNumber(number, format);
  };
}
