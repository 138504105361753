import { useLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useSearchContext } from '../../../context/search/SearchContext';
import { GearSvgIcon } from '../../GearSvgIcon';

const ClearFiltersButton: React.FC = () => {
  const localization = useLocalization();

  const { setCompositeFilterDescriptor, compositeFilterDescriptor } = useSearchContext();

  const clearAllFieldsFilters = () => {
    if (setCompositeFilterDescriptor) {
      setCompositeFilterDescriptor(undefined);
    }
  };

  return (
    <div
      title={IntlHelper.toLangStr(localization, 'custom.toolbar.filterTagsClear')}
      className={`chipsButtonLink ${compositeFilterDescriptor?.filters && compositeFilterDescriptor?.filters.length > 0 ? '' : 'chipsButtonLinkDisabled'}`}
    >
      <GearSvgIcon customIcon="clearFilterIcon" className={`gearIcon gearIconMedium`} onClick={clearAllFieldsFilters} />
    </div>
  );
};

export default ClearFiltersButton;
