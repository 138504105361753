import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { GRID_COL_INDEX_ATTRIBUTE, GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';

interface GearGridCellProps extends GridCellProps {
  children: React.ReactNode;
  title: string;
  customClassName?: string;
  customStyle?: React.CSSProperties;
}

export const GearGridCell: React.FC<GearGridCellProps> = props => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  return (
    <td
      title={props.title}
      className={`${props.className} ${props.customClassName || ''}`}
      style={{ ...props.style, ...(props.customStyle && { ...props.customStyle }) }}
      colSpan={props.colSpan}
      role={'gridcell'}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex
      }}
      {...navigationAttributes}
    >
      {props.children}
    </td>
  );
};
