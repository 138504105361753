import { TileLayout, TileLayoutItem, TilePosition } from '@progress/kendo-react-layout';
import * as React from 'react';
import { AdminCenterMenu } from './AdminCenterMenu';
import { GearSettingMenuItem } from '../../../Model/GearSettingMenuItem';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { useState } from 'react';
import { MessageBarManagement } from './MessageBarManagement';
import {
  GEAR_ADMIN_CENTER_MENU_ITEM_TYPE,
  GearAdminCenterMenuItemType
} from '../../../global/enums/GearAdminCenterMenuItemType';
import { MaintenancePageManagement } from './MaintenancePageManagement';
import { GearUiAdminConfigWriteType } from '../../../Model/uiAdminConfig/GearUiAdminConfigWriteType';
import { useUiAdminConfigsContext } from '../../../context/uiAdminConfigs/UiAdminConfigsContext';
import { GEAR_UI_ADMIN_CONFIG_TYPE } from '../../../Model/uiAdminConfig/GearUiAdminConfigType';

const postitions: TilePosition[] = [
  {
    col: 1,
    colSpan: 1
  },
  {
    col: 2,
    colSpan: 50
  }
];

interface AdminCenterManagementProps {
  uiAdminConfigWritePermissions: GearUiAdminConfigWriteType;
}

export const AdminCenterManagement: React.FC<AdminCenterManagementProps> = props => {
  const localization = useLocalization();
  const [selectedMenuItem, setSelectedMenuItem] = useState<GearAdminCenterMenuItemType>(
    GEAR_ADMIN_CENTER_MENU_ITEM_TYPE.MESSAGE_BAR
  );

  const { uiAdminConfigPreviewState, setUiAdminConfigPreviewState } = useUiAdminConfigsContext();

  React.useEffect(() => {
    if (setUiAdminConfigPreviewState && uiAdminConfigPreviewState) {
      setUiAdminConfigPreviewState({
        [GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR]: undefined,
        [GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE]: undefined
      });
    }
  }, [selectedMenuItem]);

  const menu: GearSettingMenuItem[] = [
    ...(props.uiAdminConfigWritePermissions.messageBar
      ? [
          {
            text: IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.title'),
            value: GEAR_ADMIN_CENTER_MENU_ITEM_TYPE.MESSAGE_BAR
          }
        ]
      : []),
    ...(props.uiAdminConfigWritePermissions.maintenancePage
      ? [
          {
            text: IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.maintenancePage.title'),
            value: GEAR_ADMIN_CENTER_MENU_ITEM_TYPE.MAINTENANCE_PAGE
          }
        ]
      : [])
  ];

  const getActiveBody = (menuItem: GearAdminCenterMenuItemType | undefined) => {
    switch (menuItem) {
      case GEAR_ADMIN_CENTER_MENU_ITEM_TYPE.MAINTENANCE_PAGE:
        return <MaintenancePageManagement />;
      case GEAR_ADMIN_CENTER_MENU_ITEM_TYPE.MESSAGE_BAR:
        return <MessageBarManagement />;
      default:
        return;
    }
  };

  const tiles: TileLayoutItem[] = [
    {
      body: (
        <AdminCenterMenu
          menu={menu}
          value={selectedMenuItem}
          onSelectedMenuItemChanged={(value: GearAdminCenterMenuItemType) => setSelectedMenuItem(value)}
        />
      ),
      className: 'helpCenterTileLayoutItem',
      reorderable: false,
      resizable: false
    },
    {
      body: getActiveBody(selectedMenuItem),
      className: 'helpCenterTileLayoutItem',
      reorderable: false,
      resizable: false
    }
  ];
  return (
    <div className="helpCenter">
      <TileLayout columns={2} positions={postitions} items={tiles} />
    </div>
  );
};
