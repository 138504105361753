import * as React from 'react';

import { FieldRenderProps } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { GearFieldWrapper } from '../GearFieldWrapper';

export const FormDropDownList: React.FC<FieldRenderProps> = fieldRenderProps => {
  return (
    <GearFieldWrapper {...fieldRenderProps}>
      <DropDownList
        {...fieldRenderProps}
        label={undefined}
        validationMessage={fieldRenderProps.validationMessage || undefined}
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        value={fieldRenderProps.data?.find((i: any) => i[fieldRenderProps.dataItemKey] === fieldRenderProps.value)}
      />
    </GearFieldWrapper>
  );
};
