import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { viewsKeys } from './queryKeyFactory';
import { useContext } from 'react';
import { GearView } from '../../Model/GearView';
import ServicesContext from '../../global/services/ServicesContext';

export function useGetPrivateView(locale?: string, indexId?: number): UseQueryResult<GearView, unknown> {
  const servicesCtx = useContext(ServicesContext);

  const getPrivateView = () => {
    if (servicesCtx?.viewsService) {
      return servicesCtx.viewsService.getPrivateView(locale, indexId);
    }
    throw new Error('Services context not initialized');
  };

  return useQuery({
    queryKey: viewsKeys.privateViewByLocale(locale, indexId),
    queryFn: getPrivateView,
    retry: 0,
    staleTime: 180000,
    cacheTime: 180000
  });
}
