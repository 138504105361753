import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { uiAdminConfigsKeys } from './queryKeyFactory';
import { useContext } from 'react';
import ServicesContext from '../../global/services/ServicesContext';
import { GearUiAdminConfigWriteType } from '../../Model/uiAdminConfig/GearUiAdminConfigWriteType';

export function useGetUiAdminConfigWritePermissions(
  keys?: string[]
): UseQueryResult<GearUiAdminConfigWriteType, unknown> {
  const servicesCtx = useContext(ServicesContext);

  const getUiAdminConfigEntry = () => {
    if (servicesCtx?.uiAdminConfigsService && keys) {
      return servicesCtx.uiAdminConfigsService.getUiAdminConfigWritePermissions(keys);
    }
    throw new Error('Services context not initialized');
  };

  return useQuery({
    queryKey: uiAdminConfigsKeys.uiAdminConfigWritePermissions(keys),
    queryFn: getUiAdminConfigEntry,
    enabled: !!keys,
    retry: 0,
    staleTime: Infinity,
    cacheTime: 28800000
  });
}
