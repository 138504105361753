import * as React from 'react';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { MessageBarType } from '@fluentui/react';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { FormSwitcher } from '../../common/form/fields/FormSwitcher';
import { FormDropDownList } from '../../common/form/fields/FormDropdown';
import { useUiAdminConfigsContext } from '../../../context/uiAdminConfigs/UiAdminConfigsContext';
import GearLoader from '../../common/GearLoader';
import { LoadingGridPanel } from '../../grid/LoadingGridPanel';
import { GearDictionary } from '../../../Model/GearDictionary';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FormTextArea } from '../../common/form/fields/FormMultilineText';
import { FormTextBox } from '../../common/form/fields/FormTextBox';
import { GearMessageBarState } from '../../../Model/GearMessageBarState';
import { GEAR_UI_ADMIN_CONFIG_TYPE } from '../../../Model/uiAdminConfig/GearUiAdminConfigType';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { GEAR_CONFIRM_DIALOG_STATE, GearConfirmDialogState } from '../../../Model/GearConfirmDialogState';

interface MessageBarDialogState {
  visible: boolean;
  values?: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [name: string]: any;
  };
}

export const MessageBarManagement: React.FC = () => {
  const [initialValues, setInitValues] = React.useState<Partial<GearMessageBarState>>();
  const [dialogState, setDialogState] = React.useState<MessageBarDialogState>({ visible: false });

  const localization = useLocalization();

  const messageBarTypes: GearDictionary[] = [
    {
      id: MessageBarType.info,
      name: IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.type.info')
    },
    {
      id: MessageBarType.error,
      name: IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.type.error')
    },
    {
      id: MessageBarType.blocked,
      name: IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.type.blocked')
    },
    {
      id: MessageBarType.severeWarning,
      name: IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.type.severeWarning')
    },
    {
      id: MessageBarType.success,
      name: IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.type.success')
    },
    {
      id: MessageBarType.warning,
      name: IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.type.warning')
    }
  ];

  const {
    isLoading,
    uiAdminConfigState,
    upsertUiAdminConfigEntry,
    uiAdminConfigPreviewState,
    setUiAdminConfigPreviewState
  } = useUiAdminConfigsContext();

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (uiAdminConfigState.messageBar && !initialValues) {
      const values: GearMessageBarState = uiAdminConfigState.messageBar as GearMessageBarState;
      setInitValues(values);
    } else {
      setInitValues({});
    }
  }, [uiAdminConfigState]);

  const upsertMessageBar = (values: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [name: string]: any;
  }) => {
    const body = JSON.stringify(values);
    upsertUiAdminConfigEntry(GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR, body);

    if (setUiAdminConfigPreviewState && uiAdminConfigPreviewState?.messageBar && values.enabled) {
      setUiAdminConfigPreviewState({
        ...uiAdminConfigPreviewState,
        [GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR]: undefined
      });
    }
  };

  const handleSubmit = (values: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [name: string]: any;
  }) => {
    if (values.enabled) {
      setDialogState({ visible: true, values });
    } else {
      upsertMessageBar(values);
    }
  };

  const handlePreview = (formRenderProps: FormRenderProps) => {
    if (setUiAdminConfigPreviewState) {
      const messageBarPreviewState: GearMessageBarState = {
        enabled: true,
        messageContent: formRenderProps.valueGetter('messageContent'),
        messageBarType: formRenderProps.valueGetter('messageBarType'),
        truncated: formRenderProps.valueGetter('truncated'),
        dismissable: formRenderProps.valueGetter('dismissable'),
        hyperlinkText: formRenderProps.valueGetter('hyperlinkText'),
        hyperlinkAddress: formRenderProps.valueGetter('hyperlinkAddress')
      };
      setUiAdminConfigPreviewState({
        ...uiAdminConfigPreviewState,
        [GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR]: messageBarPreviewState
      });
    }
  };

  const handleDialogConfirm = (confirmed: GearConfirmDialogState) => {
    if (confirmed === GEAR_CONFIRM_DIALOG_STATE.YES && dialogState.values) {
      upsertMessageBar(dialogState.values);
    }
    setDialogState({ visible: false });
  };

  if (!initialValues) {
    return <GearLoader />;
  }

  return (
    <div>
      {isLoading && <LoadingGridPanel />}
      <div className="gearForm">
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement>
                <Field
                  name={'enabled'}
                  component={FormSwitcher}
                  label={IntlHelper.toLangStr(localization, 'custom.common.enable')}
                />
                <Field
                  name={'messageBarType'}
                  component={FormDropDownList}
                  label={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.type.title')}
                  data={messageBarTypes}
                  textField={'name'}
                  dataItemKey={'id'}
                  onChange={(event: DropDownListChangeEvent) => {
                    formRenderProps.onChange('messageBarType', { value: event.value.id });
                  }}
                />
                <Field
                  name={'truncated'}
                  component={FormSwitcher}
                  label={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.truncated')}
                />
                <Field
                  name={'dismissable'}
                  component={FormSwitcher}
                  label={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.dismissable')}
                />
                <h3>{IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.messageContent')}</h3>
                <Field name={'messageContent'} component={FormTextArea} />
                <h3>{IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.hyperlink.title')}</h3>
                <Field
                  label={IntlHelper.toLangStr(
                    localization,
                    'custom.settings.adminCenter.messageBar.hyperlink.textToDisplay'
                  )}
                  name={'hyperlinkText'}
                  component={FormTextBox}
                />
                <Field
                  label={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.hyperlink.address')}
                  name={'hyperlinkAddress'}
                  component={FormTextBox}
                />
                <div className={'gearFormButtons'}>
                  <Button
                    type={'button'}
                    themeColor={'secondary'}
                    fillMode="solid"
                    onClick={() => handlePreview(formRenderProps)}
                  >
                    {IntlHelper.toLangStr(localization, 'custom.common.preview')}
                  </Button>
                  <Button themeColor="dark" disabled={!formRenderProps.modified}>
                    {IntlHelper.toLangStr(localization, 'custom.common.save')}
                  </Button>
                </div>
              </FormElement>
            );
          }}
        />
      </div>
      {dialogState.visible && (
        <ConfirmDialog
          message={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.messageBar.confirmationMessage')}
          onConfirm={handleDialogConfirm}
        />
      )}
    </div>
  );
};
