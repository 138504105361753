import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { RadioButton } from '@progress/kendo-react-inputs';
import { GearGridCell } from './GearGridCell';
import { useLocalization } from '@progress/kendo-react-intl';
import { IntlHelper } from '../../../helpers/IntlHelper';

export interface RadioButtonCellProps extends GridCellProps {
  onRadioButtonChange?: () => void;
  disabled?: boolean;
}

export const RadioButtonCell: React.FC<RadioButtonCellProps> = props => {
  const { disabled, onRadioButtonChange } = props;
  const field = props.field;
  const selectedValue = field ? props.dataItem[field] : false;
  const localization = useLocalization();
  return (
    <GearGridCell
      {...props}
      title={IntlHelper.toLangStr(localization, `custom.common.${selectedValue ? 'yes' : 'no'}`)}
    >
      <RadioButton
        checked={selectedValue}
        disabled={disabled}
        onChange={onRadioButtonChange}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      />
    </GearGridCell>
  );
};
