import * as React from 'react';
import { TextArea } from '@progress/kendo-react-inputs';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { GearFieldWrapper } from '../GearFieldWrapper';

export const FormTextArea: React.FC<FieldRenderProps> = fieldRenderProps => {
  return (
    <GearFieldWrapper {...fieldRenderProps}>
      <TextArea {...fieldRenderProps} rows={5} validationMessage={fieldRenderProps.validationMessage || undefined} />
    </GearFieldWrapper>
  );
};
