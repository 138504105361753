import * as React from 'react';
import { SvgIcon } from '@progress/kendo-react-common';
import { reorderIcon } from '@progress/kendo-svg-icons';
import { GridCellProps } from '@progress/kendo-react-grid';

interface DragHandleCellProps extends GridCellProps {}

export const DragHandleCell: React.FC<DragHandleCellProps> = props => {
  return (
    <td className={props.className} style={{ touchAction: 'none' }} colSpan={props.colSpan} role={'gridcell'}>
      <span
        style={{
          cursor: 'move'
        }}
        data-drag-handle={true}
      >
        <SvgIcon
          icon={reorderIcon}
          style={{
            pointerEvents: 'none'
          }}
        />
      </span>
    </td>
  );
};
