import { GearUiAdminConfigWriteType } from '../../Model/uiAdminConfig/GearUiAdminConfigWriteType';
import { FieldResponseDto } from '../../Model/responseDtos/FieldResponseDto';
import { ExecuteEndpoint } from '../../components/common/BaseApiService';
import { GearUiAdminConfigType } from '../../Model/uiAdminConfig/GearUiAdminConfigType';

export class UiAdminConfigsService {
  public async getUiAdminConfigEntry(key: GearUiAdminConfigType): Promise<FieldResponseDto[]> {
    return ExecuteEndpoint(`ui-admin-config-entries/${key}`, 'GET');
  }

  public async upsertUiAdminConfigEntry(key: GearUiAdminConfigType, body: string): Promise<void> {
    return ExecuteEndpoint(`ui-admin-config-entries/${key}`, 'POST', {
      body,
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'text/plain'
      }
    });
  }

  public async getUiAdminConfigWritePermissions(keys: string[]): Promise<GearUiAdminConfigWriteType> {
    return ExecuteEndpoint(`ui-admin-config-entries/write-permissions?${keys.map(i => `keys=${i}`).join('&')}`, 'GET');
  }
}
