import * as React from 'react';
import { GlobalFilterState } from './GlobalFilterState';
import {
  DatePicker,
  DatePickerChangeEvent,
  DateTimePicker,
  DateTimePickerChangeEvent,
  MultiViewCalendarProps
} from '@progress/kendo-react-dateinputs';
import { RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import { GEAR_FIELD_TYPE } from '../../../../global/enums/GearFieldType';
import { IntlHelper } from '../../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { DatePickerCalendar } from './dateTimeRange/DatePickerCalendar';
import { DateTimePickerCalendar } from './dateTimeRange/DateTimePickerCalendar';
import { useSearchContext } from '../../../../context/search/SearchContext';
import { CompositeFilterDescriptor, FilterDescriptor, FilterOperator } from '@progress/kendo-data-query';
import { useDocumentsContext } from '../../../../context/documents/DocumentsContext';
import { GearFilterRequest } from '../../../../Model/GearFilterRequest';
import { getGearDateFilterFormat, getGearDateTimeFilterFormat } from '../../../../helpers/DayJsHelper';
import { useRef } from 'react';

interface GroupedDateGlobalFilterProps {
  dateFiltersState: GlobalFilterState[];
  fieldSelected?: GlobalFilterState;
  setFieldSelected: (newFieldSelected?: GlobalFilterState) => void;
}

const PICKER_START_ID = 'dateTimePickerStart';
const PICKER_END_ID = 'dateTimePickerEnd';

export const GroupedDateGlobalFilter: React.FC<GroupedDateGlobalFilterProps> = props => {
  const { fieldSelected, setFieldSelected } = props;
  const localization = useLocalization();
  const { queryRequest, setQueryRequest, compositeFilterDescriptor, setCompositeFilterDescriptor } = useSearchContext();
  const { setSelectedDocuments } = useDocumentsContext();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const dateTimeStartRef = useRef<any>(null);
  const dateTimeEndRef = useRef<any>(null);
  const dateStartRef = useRef<any>(null);
  const dateEndRef = useRef<any>(null);

  React.useEffect(() => {
    if (!fieldSelected) {
      setFieldSelected(props.dateFiltersState[0]);
    }
  }, []);

  const closeOpenedPickers = () => {
    if (dateTimeStartRef?.current && dateTimeStartRef.current.state.show) {
      dateTimeStartRef.current.handleBlur();
    }
    if (dateTimeEndRef?.current && dateTimeEndRef.current.state.show) {
      dateTimeEndRef.current.handleBlur();
    }
    if (dateStartRef?.current && dateStartRef.current.state.show) {
      dateStartRef.current.handleBlur();
    }
    if (dateEndRef?.current && dateEndRef.current.state.show) {
      dateEndRef.current.handleBlur();
    }
  };

  const handleFieldChange = (event: RadioButtonChangeEvent) => {
    if (queryRequest && setQueryRequest && setCompositeFilterDescriptor && fieldSelected) {
      const newFilters =
        queryRequest.filters?.filter(
          filter => !props.dateFiltersState.map(state => state.name).includes(filter.fieldName)
        ) || [];
      setQueryRequest({
        ...queryRequest,
        filters: newFilters
      });
      if (setSelectedDocuments) {
        setSelectedDocuments([]);
      }
      closeOpenedPickers();
    }
    const newFieldSelected = props.dateFiltersState.find(filter => filter.id === event.value);
    setFieldSelected(newFieldSelected);
  };

  if (!fieldSelected) {
    return <></>;
  }

  const handleChange = (event: DateTimePickerChangeEvent | DatePickerChangeEvent) => {
    const newValue = event.value as Date;
    const pickerId = event.target.props.id as typeof PICKER_START_ID | typeof PICKER_END_ID;
    if (queryRequest && setQueryRequest && setCompositeFilterDescriptor) {
      const otherFieldFilters = queryRequest.filters?.filter(
        filter =>
          filter.fieldName !== fieldSelected.name ||
          (filter.fieldName === fieldSelected.name &&
            pickerId === PICKER_START_ID &&
            filter.operator === FilterOperator.LessThanOrEqual) ||
          (filter.fieldName === fieldSelected.name &&
            pickerId === PICKER_END_ID &&
            filter.operator === FilterOperator.GreaterThanOrEqual)
      );
      if (compositeFilterDescriptor) {
        const newCompositeFilterDescriptor: CompositeFilterDescriptor = {
          ...compositeFilterDescriptor,
          filters: (compositeFilterDescriptor.filters as CompositeFilterDescriptor[]).filter(filter1 =>
            (filter1.filters as FilterDescriptor[]).some(filter2 => filter2.field !== fieldSelected.name)
          )
        };
        setCompositeFilterDescriptor(newCompositeFilterDescriptor);
      }

      const newFilters = otherFieldFilters || [];
      if (newValue && (Array.isArray(newValue) ? newValue.length > 0 : true)) {
        switch (pickerId) {
          case PICKER_START_ID:
            const startFilter: GearFilterRequest = {
              fieldName: fieldSelected.name,
              operator: FilterOperator.GreaterThanOrEqual,
              values: [
                fieldSelected.type === GEAR_FIELD_TYPE.DATETIME
                  ? getGearDateTimeFilterFormat(newValue)
                  : getGearDateFilterFormat(newValue)
              ],
              fieldType: GEAR_FIELD_TYPE.DATE,
              isGlobal: true
            } as GearFilterRequest;
            newFilters.push(startFilter);
            break;
          case PICKER_END_ID:
            const endFilter: GearFilterRequest = {
              fieldName: fieldSelected.name,
              operator: FilterOperator.LessThanOrEqual,
              values: [
                fieldSelected.type === GEAR_FIELD_TYPE.DATETIME
                  ? getGearDateTimeFilterFormat(newValue)
                  : getGearDateFilterFormat(newValue)
              ],
              fieldType: GEAR_FIELD_TYPE.DATE,
              isGlobal: true
            } as GearFilterRequest;
            newFilters.push(endFilter);
            break;
          default:
            break;
        }
      }
      setQueryRequest({
        ...queryRequest,
        filters: newFilters
      });
      if (setSelectedDocuments) {
        setSelectedDocuments([]);
      }
    }
  };

  const currentValueStart = queryRequest?.filters?.find(
    f => f.fieldName === fieldSelected.name && f.isGlobal && f.operator === FilterOperator.GreaterThanOrEqual
  )?.values as string[] | undefined;
  const currentValueEnd = queryRequest?.filters?.find(
    f => f.fieldName === fieldSelected.name && f.isGlobal && f.operator === FilterOperator.LessThanOrEqual
  )?.values as string[] | undefined;

  return (
    <div className="dateTimeRangeFilter">
      {fieldSelected.type === GEAR_FIELD_TYPE.DATETIME ? (
        <>
          <DateTimePicker
            ref={dateTimeStartRef}
            label={`${fieldSelected.displayName} (${IntlHelper.toLangStr(localization, 'daterangepicker.start')})`}
            id={PICKER_START_ID}
            value={currentValueStart && currentValueStart.length > 0 ? new Date(currentValueStart[0]) : null}
            calendar={calendarProps => (
              <DateTimePickerCalendar
                {...calendarProps}
                dateFiltersState={props.dateFiltersState}
                fieldSelected={fieldSelected}
                handleFieldChange={handleFieldChange}
              />
            )}
            onChange={handleChange}
            onOpen={closeOpenedPickers}
          />
          <DateTimePicker
            ref={dateTimeEndRef}
            label={`${fieldSelected.displayName} (${IntlHelper.toLangStr(localization, 'daterangepicker.end')})`}
            id={PICKER_END_ID}
            value={currentValueEnd && currentValueEnd.length > 0 ? new Date(currentValueEnd[0]) : null}
            calendar={calendarProps => (
              <DateTimePickerCalendar
                {...calendarProps}
                dateFiltersState={props.dateFiltersState}
                fieldSelected={fieldSelected}
                handleFieldChange={handleFieldChange}
              />
            )}
            onChange={handleChange}
            onOpen={closeOpenedPickers}
          />
        </>
      ) : (
        <>
          <DatePicker
            ref={dateStartRef}
            label={`${fieldSelected.displayName} (${IntlHelper.toLangStr(localization, 'daterangepicker.start')})`}
            id={PICKER_START_ID}
            value={currentValueStart && currentValueStart.length > 0 ? new Date(currentValueStart[0]) : null}
            calendar={(calendarProps: MultiViewCalendarProps) => (
              <DatePickerCalendar
                {...calendarProps}
                dateFiltersState={props.dateFiltersState}
                fieldSelected={fieldSelected}
                handleFieldChange={handleFieldChange}
              />
            )}
            onChange={handleChange}
            onOpen={closeOpenedPickers}
          />
          <DatePicker
            ref={dateEndRef}
            label={`${fieldSelected.displayName} (${IntlHelper.toLangStr(localization, 'daterangepicker.end')})`}
            id={PICKER_END_ID}
            value={currentValueEnd && currentValueEnd.length > 0 ? new Date(currentValueEnd[0]) : null}
            calendar={(calendarProps: MultiViewCalendarProps) => (
              <DatePickerCalendar
                {...calendarProps}
                dateFiltersState={props.dateFiltersState}
                fieldSelected={fieldSelected}
                handleFieldChange={handleFieldChange}
              />
            )}
            onChange={handleChange}
            onOpen={closeOpenedPickers}
          />
        </>
      )}
    </div>
  );
};
