import * as React from 'react';
import { SearchContext } from './SearchContext';
import { useSearch } from './useSearch';
import { GearSearchQueryRequest } from '../../Model/GearSearchQueryRequest';
import { useState } from 'react';
import { DEFAULT_ROWS_PER_PAGE } from '../../config/consts';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useTriggerExportToCsv } from './useSearchExport';
import { useNotificationContext } from '../../global/hooks/useNotificationContext';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { GearSearchExportRequest } from '../../Model/GearSearchExportRequest';
import { useCurrentUserContext } from '../currentUser/CurrentUserContext';

interface ViewsProviderProps {
  children?: React.ReactNode;
}

const initQueryRequest: GearSearchQueryRequest = {
  size: DEFAULT_ROWS_PER_PAGE,
  includeTotalCount: true
};

export const SearchProvider: React.FC<ViewsProviderProps> = ({ children }) => {
  const { selectedIndex } = useCurrentUserContext();
  const [queryRequest, setQueryRequest] = useState<GearSearchQueryRequest>(initQueryRequest);
  const { data, isLoading } = useSearch(queryRequest);
  const [compositeFilterDescriptor, setCompositeFilterDescriptor] = useState<CompositeFilterDescriptor | undefined>();
  const { mutate: mutateTrigerExportToCsv } = useTriggerExportToCsv();
  const { showNotification } = useNotificationContext();
  const localization = useLocalization();

  const handleTrigerExportToCsv = (dto: GearSearchExportRequest): void => {
    mutateTrigerExportToCsv(
      { dto: { ...dto, indexId: selectedIndex?.id } },
      {
        onSuccess: () => {
          showNotification({
            type: 'success',
            message: IntlHelper.toLangStr(localization, 'custom.notifications.exportCsv')
          });
        }
      }
    );
  };

  return (
    <SearchContext.Provider
      value={{
        data,
        isLoading,
        queryRequest,
        setQueryRequest,
        compositeFilterDescriptor,
        setCompositeFilterDescriptor,
        handleTrigerExportToCsv
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
