import * as React from 'react';
import { GlobalFilterState } from './GlobalFilterState';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { GEAR_FIELD_TYPE } from '../../../../global/enums/GearFieldType';
import { useSearchContext } from '../../../../context/search/SearchContext';
import { SelectionRange } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { CompositeFilterDescriptor, FilterDescriptor, FilterOperator } from '@progress/kendo-data-query';
import { GearFilterRequest } from '../../../../Model/GearFilterRequest';
import { useMemo } from 'react';
import { useDocumentsContext } from '../../../../context/documents/DocumentsContext';

interface GlobalFilterProps {
  filterState: GlobalFilterState;
}

export const GlobalFilter: React.FC<GlobalFilterProps> = props => {
  const { filterState } = props;
  const { queryRequest, setQueryRequest, compositeFilterDescriptor, setCompositeFilterDescriptor } = useSearchContext();
  const { setSelectedDocuments } = useDocumentsContext();
  const currentValue = useMemo(
    () => queryRequest?.filters?.find(f => f.fieldName === filterState.name && f.isGlobal)?.values,
    [queryRequest?.filters]
  );

  const handleChange = (
    filterOperator: FilterOperator,
    newValue: string[] | boolean[] | number[] | undefined | SelectionRange
  ) => {
    if (queryRequest && setQueryRequest && setCompositeFilterDescriptor) {
      const otherFieldFilters = queryRequest.filters?.filter(filter => filter.fieldName !== filterState.name);
      if (compositeFilterDescriptor) {
        const newCompositeFilterDescriptor: CompositeFilterDescriptor = {
          ...compositeFilterDescriptor,
          filters: (compositeFilterDescriptor.filters as CompositeFilterDescriptor[]).filter(filter1 =>
            (filter1.filters as FilterDescriptor[]).some(filter2 => filter2.field !== filterState.name)
          )
        };
        setCompositeFilterDescriptor(newCompositeFilterDescriptor);
      }

      const newFilters = otherFieldFilters || [];
      if (newValue && (Array.isArray(newValue) ? newValue.length > 0 : true)) {
        const newFilter: GearFilterRequest = {
          fieldName: filterState.name,
          operator: filterOperator,
          values: newValue,
          fieldType: filterState.type,
          isGlobal: true
        } as GearFilterRequest;
        newFilters.push(newFilter);
      }

      setQueryRequest({
        ...queryRequest,
        filters: newFilters
      });
      if (setSelectedDocuments) {
        setSelectedDocuments([]);
      }
    }
  };

  if (filterState.facetValues) {
    return (
      <MultiSelect
        label={filterState.displayName}
        data={filterState.facetValues.map(facetValue => facetValue.value)}
        value={currentValue || []}
        onChange={e => handleChange(FilterOperator.EqualTo, e.target.value)}
        id={filterState.id?.toString()}
      />
    );
  } else {
    switch (filterState.type) {
      case GEAR_FIELD_TYPE.BOOLEAN:
        return (
          <MultiSelect
            label={filterState.displayName}
            data={[true, false]}
            value={currentValue || []}
            onChange={e => handleChange(FilterOperator.EqualTo, e.target.value)}
            id={filterState.id?.toString()}
          />
        );
      case GEAR_FIELD_TYPE.NUMERIC:
        return (
          <Input
            id={filterState.id.toString()}
            label={filterState.displayName}
            value={currentValue?.length === 1 && currentValue ? (currentValue[0] as number) : undefined}
            type="number"
            onBlur={e => handleChange(FilterOperator.EqualTo, e.target.value ? [e.target.value] : undefined)}
          />
        );
      case GEAR_FIELD_TYPE.TEXT:
        return (
          <Input
            id={filterState.id?.toString()}
            label={filterState.displayName}
            value={currentValue?.length === 1 && currentValue ? (currentValue[0] as string) : undefined}
            onBlur={e => handleChange(FilterOperator.Contains, e.target.value ? [e.target.value] : undefined)}
          />
        );
      default:
        return <></>;
    }
  }
};
