import { useLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { IntlHelper } from '../../../../helpers/IntlHelper';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useViewsContext } from '../../../../context/view/ViewsContext';
import { SETTINGS_VIEW_FIELD, SettingsViewDataItem } from '../SettingsViewDataItem';

export const NoPrivateViewContent: React.FC = () => {
  const localization = useLocalization();
  const { defaultSystemView, setPrivateViewState } = useViewsContext();

  const createPrivateViewBasedOnDefault = () => {
    if (setPrivateViewState && defaultSystemView) {
      const data = defaultSystemView.fields.map(
        field =>
          ({
            [SETTINGS_VIEW_FIELD.ID]: field.id,
            [SETTINGS_VIEW_FIELD.NAME]: field.displayName || field.name,
            [SETTINGS_VIEW_FIELD.WIDTH]: field.width,
            [SETTINGS_VIEW_FIELD.LINK]: field.isDocumentLink
          }) as SettingsViewDataItem
      );
      setPrivateViewState({ data, activeItem: null });
    }
  };

  return (
    <div>
      <StackLayout
        orientation={'horizontal'}
        align={{ horizontal: 'center', vertical: 'middle' }}
        gap={25}
        style={{ margin: 25 }}
      >
        <h3>{IntlHelper.toLangStr(localization, 'custom.settings.views.private.noView')}</h3>
        {defaultSystemView && (
          <Button
            themeColor="primary"
            title={IntlHelper.toLangStr(localization, 'custom.settings.views.private.addNew')}
            style={{ maxWidth: 150 }}
            onClick={createPrivateViewBasedOnDefault}
          >
            {IntlHelper.toLangStr(localization, 'custom.settings.views.private.addNew')}
          </Button>
        )}
      </StackLayout>
    </div>
  );
};
