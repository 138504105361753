import * as React from 'react';
import { useViewsContext } from '../../../context/view/ViewsContext';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useMemo } from 'react';
import { RadioButtonCell } from '../../grid/customCells/RadioButtonCell';
import { SETTINGS_VIEW_FIELD, SettingsViewDataItem } from './SettingsViewDataItem';
import { ViewGrid } from './ViewGrid';
import { GearGridCell } from '../../grid/customCells/GearGridCell';

export const DefaultView: React.FC = () => {
  const { defaultSystemView: defaultView, defaultSystemViewLoading: defaultViewLoading } = useViewsContext();
  const data: SettingsViewDataItem[] = useMemo(
    () =>
      defaultView
        ? defaultView.fields.map(field => ({
            [SETTINGS_VIEW_FIELD.ID]: field.id,
            [SETTINGS_VIEW_FIELD.NAME]: field.displayName || field.name,
            [SETTINGS_VIEW_FIELD.WIDTH]: field.width,
            [SETTINGS_VIEW_FIELD.LINK]: field.isDocumentLink
          }))
        : [],
    [defaultView]
  );

  const handleCellRender = React.useCallback(
    (tdElement: React.ReactElement<HTMLTableCellElement> | null, cellProps: GridCellProps) => {
      if (!cellProps.field) {
        return tdElement;
      }
      if (cellProps.field === SETTINGS_VIEW_FIELD.LINK) {
        return <RadioButtonCell {...cellProps} disabled={true} />;
      }
      return (
        <GearGridCell {...cellProps} title={cellProps.dataItem[cellProps.field] || ''}>
          {cellProps.dataItem[cellProps.field] || ''}
        </GearGridCell>
      );
    },
    [data]
  );

  return <ViewGrid type="DEFAULT_VIEW" data={data} cellRender={handleCellRender} isLoading={defaultViewLoading} />;
};
