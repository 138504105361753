import { createContext, useContext } from 'react';
import { GearUser } from '../../Model/GearUser';
import { Nullable } from '../../Model/CommonProps';
import { GearIndex } from '../../Model/GearIndex';

interface ICurrentUserContext {
  currentUser?: Nullable<GearUser>;
  isLoading?: boolean;
  selectedIndex?: GearIndex;
  setSelectedIndex?: (selectedIndex: GearIndex) => void;
}

export const CurrentUserContext = createContext<ICurrentUserContext>({
  currentUser: undefined
});

export const useCurrentUserContext = () => useContext(CurrentUserContext);
