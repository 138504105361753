import * as React from 'react';

interface GearPageProps {
  children: React.ReactNode;
  className?: string;
}

const GearPage: React.FC<GearPageProps> = props => {
  return <div className={`gearPage ${props.className || ''}`}>{props.children}</div>;
};

export default GearPage;
