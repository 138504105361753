import { LocalizationService } from '@progress/kendo-react-intl';
import { RICH_TEXT_CHARACTER_LIMIT } from '../../../../config/consts';
import { IntlHelper } from '../../../../helpers/IntlHelper';

export const maxLengthRichTextEditor = (value: string, localization: LocalizationService): string | undefined => {
  if (
    value !== undefined &&
    value !== '' &&
    value !== null &&
    value.replace(/<[^>]+>/g, '').length <= RICH_TEXT_CHARACTER_LIMIT
  ) {
    return undefined;
  }
  return IntlHelper.toLangStr(localization, 'custom.common.validation.editorContentExceeded');
};
