import { useContext } from 'react';
import { INotificationContext, NotificationContext } from '../../context/notification/NotificationContext';

export const useNotificationContext = (): INotificationContext => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext must be used within a NotificationContextProvider');
  }
  return context;
};
