import { GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';
import * as React from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { EMPTY_VALUE } from '../../../config/consts';
import { GearGridCell } from './GearGridCell';
export interface EditableDropdownCellProps extends GridCellProps {
  onItemChange: (event: GridItemChangeEvent) => void;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  options: any[];
  dataItemKey?: string;
  textField?: string;
}

export const EditableDropdownCell: React.FC<EditableDropdownCellProps> = props => {
  const field = props.field || EMPTY_VALUE;
  const value = props.dataItem[field];

  const handleChange = (event: DropDownListChangeEvent): void => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const newEvent: any = {
      ...event,
      dataItem: props.dataItem,
      field: field
    };
    props.onItemChange(newEvent);
  };

  return (
    <GearGridCell {...props} title={value?.toString()}>
      {
        <DropDownList
          disabled={false}
          size="small"
          onChange={handleChange}
          dataItemKey={props.dataItemKey}
          textField={props.textField}
          data={props.options}
          value={value}
        />
      }
    </GearGridCell>
  );
};
