import { DropDownButton, DropDownButtonItem, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { useNavigate } from 'react-router';
import { RoutePath } from '../../global/enums/RoutePath';
import { gearIcon, gearsIcon, logoutIcon } from '@progress/kendo-svg-icons';

const SettingsButton: React.FC = () => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const settingsText = IntlHelper.toLangStr(localization, 'custom.settings.title');
  const logoutText = IntlHelper.toLangStr(localization, 'custom.settings.logout');

  const handleItemClicked = (event: DropDownButtonItemClickEvent) => {
    switch (event.item.text) {
      case settingsText:
        navigate(`/${RoutePath.SETTINGS}/${RoutePath.SETTINGS_VIEWS}`);
        break;
      case logoutText:
        navigate(RoutePath.LOGOUT);
        break;
      default:
        break;
    }
  };

  return (
    <DropDownButton
      svgIcon={gearIcon}
      themeColor={'primary'}
      onItemClick={handleItemClicked}
      popupSettings={{ popupClass: 'settingsPopupContent' }}
    >
      <DropDownButtonItem svgIcon={gearsIcon} text={settingsText} />
      <DropDownButtonItem svgIcon={logoutIcon} text={logoutText} />
    </DropDownButton>
  );
};

export default SettingsButton;
