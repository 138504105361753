import { GridColumnMenuCheckboxFilter, GridColumnMenuProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { GearFacetValue } from '../../../Model/GearFacets';
import * as _ from 'lodash';

interface GridColumnMenuCheckboxFilterWrapperProps extends GridColumnMenuProps {
  field: string;
  fieldFacets: GearFacetValue[];
  expanded?: boolean;
  onExpandChange: (nextExpandState: boolean) => void;
}

export const GridColumnMenuCheckboxFilterWrapper: React.FC<GridColumnMenuCheckboxFilterWrapperProps> = props => {
  const { field, fieldFacets, expanded, onExpandChange } = props;
  const data = _.orderBy(
    fieldFacets.map(facet => ({ [field]: facet.value })),
    [field]
  );

  return (
    <GridColumnMenuCheckboxFilter
      {...props}
      data={data}
      expanded={expanded}
      onExpandChange={onExpandChange}
      searchBoxFilterOperator={'contains'}
    />
  );
};
