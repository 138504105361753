import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fieldsKeys } from './queryKeyFactory';
import { useContext } from 'react';
import ServicesContext from '../../global/services/ServicesContext';
import { GearField } from '../../Model/GearField';

export function useGetAllFields(locale?: string, indexId?: number): UseQueryResult<GearField[], unknown> {
  const servicesCtx = useContext(ServicesContext);

  const getAllFields = () => {
    if (servicesCtx?.fieldsService) {
      return servicesCtx.fieldsService.getAllFields(locale, indexId);
    }
    throw new Error('Services context not initialized');
  };

  return useQuery({
    queryKey: fieldsKeys.fieldsByLocale(locale, indexId),
    queryFn: getAllFields,
    retry: 0,
    staleTime: 180000,
    cacheTime: 180000
  });
}
