import { useContext } from 'react';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { mutateViewsKeys } from './mutateKeyFactory';
import ServicesContext from '../../global/services/ServicesContext';
import { viewsKeys } from './queryKeyFactory';
import { CreateUpdateViewRequestDto } from '../../Model/CreateUpdateViewRequestDto';
import { searchKeys } from '../search/queryKeyFactory';

export function useCreateUpdatePrivateView(
  createUpdateViewRequest: CreateUpdateViewRequestDto,
  locale?: string,
  indexId?: number
): UseMutationResult<boolean, unknown> {
  const servicesCtx = useContext(ServicesContext);
  const queryClient = useQueryClient();

  const createUpdatePrivateView = (): Promise<boolean> => {
    if (servicesCtx?.viewsService) {
      return servicesCtx.viewsService.createUpdatePrivateView(createUpdateViewRequest);
    }
    throw new Error('Services context not initialized');
  };

  return useMutation({
    mutationKey: mutateViewsKeys.createUpdatePrivateView,
    mutationFn: () => createUpdatePrivateView(),
    onSuccess: async data => {
      if (data) {
        await queryClient.invalidateQueries(viewsKeys.privateViewByLocale(locale, indexId));
        await queryClient.invalidateQueries(viewsKeys.defaultViewByLocale(true, locale, indexId));
        await queryClient.invalidateQueries(searchKeys.all);
      }
    }
  });
}
