import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { viewsKeys } from './queryKeyFactory';
import { useContext } from 'react';
import ServicesContext from '../../global/services/ServicesContext';
import { GearDictionary } from '../../Model/GearDictionary';

export function useGetLocales(): UseQueryResult<GearDictionary[], unknown> {
  const servicesCtx = useContext(ServicesContext);

  const getLocales = async () => {
    if (servicesCtx?.localesService) {
      return servicesCtx.localesService.getLocales();
    }
    throw new Error('Services context not initialized');
  };

  return useQuery({
    queryKey: viewsKeys.all,
    queryFn: getLocales,
    staleTime: Infinity,
    cacheTime: Infinity
  });
}
