import * as React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { defaultMessages } from '../../config/globalization/globalizationSetup';
import * as _ from 'lodash';

interface MessageProps {
  messageKey: string;
}

export const Message: React.FC<MessageProps> = props => {
  const localization = useLocalization();
  return (
    <span>
      {localization.toLanguageString(
        props.messageKey,
        _.get(defaultMessages, props.messageKey) || `${props.messageKey}`
      )}
    </span>
  );
};
