import { DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useEffect, useMemo } from 'react';
import { useDocumentsContext } from '../../../context/documents/DocumentsContext';
import { useSearchContext } from '../../../context/search/SearchContext';
import { GearSearchExportRequest } from '../../../Model/GearSearchExportRequest';
import { useViewsContext } from '../../../context/view/ViewsContext';
import { SvgIcon } from '@progress/kendo-react-common';
import { SVGIcon, chevronDownIcon, downloadIcon, exportIcon } from '@progress/kendo-svg-icons';
import { Label } from '@fluentui/react';
import { useGlobalizationContext } from '../../../context/globalization/GlobalizationContext';
import { MAX_ITEMS_LIMIT_EXPORT_CSV, TOO_MANY_REQUESTS_EXPORT_CSV } from '../../../config/consts';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import * as _ from 'lodash';

enum ActionItemId {
  DOWNLOAD = 'download',
  EXPORT = 'export'
}

interface DropDownButtonItem {
  id: ActionItemId;
  text: string;
  svgIcon: SVGIcon;
}

const CONFIRM_DIALOG = {
  MAX_EXPORT_ITEMS: 'MAX_EXPORT_ITEMS',
  TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS'
};

export type ConfirmDialogType = (typeof CONFIRM_DIALOG)[keyof typeof CONFIRM_DIALOG];

interface TooManyRequestsTimer {
  querySearchExport: GearSearchExportRequest;
  timeLeft: number;
}

const FilterActions: React.FC = () => {
  const { data, queryRequest } = useSearchContext();
  const { defaultUserView } = useViewsContext();
  const localization = useLocalization();
  const { selectedLocale } = useGlobalizationContext();
  const { selectedDocuments, downloadFiles } = useDocumentsContext();
  const { handleTrigerExportToCsv } = useSearchContext();
  const [dialogVisible, setDialogVisible] = React.useState<ConfirmDialogType | undefined>(undefined);
  const [tooManyRequestsTimer, setTooManyRequestsTimer] = React.useState<TooManyRequestsTimer>();

  useEffect(() => {
    if (!tooManyRequestsTimer || !tooManyRequestsTimer?.timeLeft) {
      if (tooManyRequestsTimer && tooManyRequestsTimer.timeLeft === 0) {
        setTooManyRequestsTimer(undefined);
        if (dialogVisible === CONFIRM_DIALOG.TOO_MANY_REQUESTS) {
          setDialogVisible(undefined);
        }
      }
      return;
    }

    const intervalId = setInterval(() => {
      setTooManyRequestsTimer({
        ...tooManyRequestsTimer,
        timeLeft: tooManyRequestsTimer.timeLeft - 1
      });
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [tooManyRequestsTimer?.timeLeft]);

  const selectedDocIdsArray = useMemo(() => {
    if (!selectedDocuments) {
      return [];
    }
    return selectedDocuments;
  }, [selectedDocuments]);

  const items: DropDownButtonItem[] = useMemo(() => {
    const result = [
      {
        id: ActionItemId.EXPORT,
        text: IntlHelper.toLangStr(localization, 'custom.toolbar.actions.export.action'),
        svgIcon: exportIcon
      }
    ];
    if (selectedDocIdsArray.length > 0) {
      result.push({
        id: ActionItemId.DOWNLOAD,
        text: IntlHelper.toLangStr(
          localization,
          selectedDocIdsArray.length === 1
            ? 'custom.toolbar.actions.downloadFile'
            : 'custom.toolbar.actions.downloadZip'
        ),
        svgIcon: downloadIcon
      });
    }
    return result;
  }, [selectedDocIdsArray.length, localization.language]);

  const itemClicked = async (event: DropDownButtonItemClickEvent) => {
    switch (event.item.id) {
      case ActionItemId.DOWNLOAD:
        if (downloadFiles) {
          return downloadFiles();
        }
        return;
      case ActionItemId.EXPORT:
        if (handleTrigerExportToCsv) {
          if (data && data.totalCount > MAX_ITEMS_LIMIT_EXPORT_CSV) {
            setDialogVisible(CONFIRM_DIALOG.MAX_EXPORT_ITEMS);
            return;
          }
          const querySearchExport: GearSearchExportRequest = {
            filters: queryRequest?.filters,
            searchText: queryRequest?.searchText,
            searchFields: queryRequest?.searchFields,
            orderBy: queryRequest?.orderBy,
            orderByIsDescending: queryRequest?.orderByIsDescending,
            select: defaultUserView?.fields.map(column => column.name),
            locale: selectedLocale
          };
          if (tooManyRequestsTimer && _.isEqual(querySearchExport, tooManyRequestsTimer.querySearchExport)) {
            setDialogVisible(CONFIRM_DIALOG.TOO_MANY_REQUESTS);
            return;
          }
          setTooManyRequestsTimer({ querySearchExport, timeLeft: TOO_MANY_REQUESTS_EXPORT_CSV });
          return handleTrigerExportToCsv(querySearchExport);
        }
        return;
      default:
        return;
    }
  };

  const handleItemRender = (li: { item: DropDownButtonItem; itemIndex: number }) => {
    return (
      <div className={`filterActionButtonOption`}>
        <SvgIcon icon={li.item.svgIcon} />
        <Label key={li.item.id}>{li.item.text}</Label>
      </div>
    );
  };

  return (
    <div>
      <DropDownButton
        disabled={data?.documents.length === 0}
        className="dropDownButtonIconRight"
        svgIcon={chevronDownIcon}
        text={IntlHelper.toLangStr(localization, 'custom.toolbar.actions.title')}
        items={items}
        itemRender={handleItemRender}
        themeColor={'base'}
        fillMode="outline"
        onItemClick={itemClicked}
      />
      {dialogVisible && (
        <ConfirmDialog
          className="filterActionsDialog"
          title={IntlHelper.toLangStr(
            localization,
            dialogVisible === CONFIRM_DIALOG.MAX_EXPORT_ITEMS
              ? 'custom.toolbar.actions.export.maxExportItems.dialogHeader'
              : 'custom.toolbar.actions.export.tooManyRequests.dialogHeader'
          )}
          message={
            IntlHelper.toLangStr(
              localization,
              dialogVisible === CONFIRM_DIALOG.MAX_EXPORT_ITEMS
                ? 'custom.toolbar.actions.export.maxExportItems.dialogBody'
                : 'custom.toolbar.actions.export.tooManyRequests.dialogBody'
            ) + `${tooManyRequestsTimer ? ` (${tooManyRequestsTimer?.timeLeft}s)` : ''}`
          }
          confirmButtonOnly={true}
          onConfirm={() => setDialogVisible(undefined)}
        />
      )}
    </div>
  );
};

export default FilterActions;
