import { KyResponse } from 'ky';
import { ExecuteGetFile } from '../../components/common/BaseApiService';

export const buildDocumentEndpointPath = (
  contentType: string,
  documentId: string,
  indexId?: number,
  displayInBrowser?: boolean
) =>
  `content-types/${contentType}/documents/${documentId}/content?indexId=${indexId}&displayInBrowser=${displayInBrowser}`;

export class DocumentsService {
  public async getDocumentContent(
    contentType: string,
    documentId: string,
    indexId?: number,
    displayInBrowser?: boolean
  ): Promise<KyResponse> {
    const endpoint = buildDocumentEndpointPath(contentType, documentId, indexId, displayInBrowser);
    return ExecuteGetFile(endpoint);
  }
}
