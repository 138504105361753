import { TileLayout, TileLayoutItem, TilePosition } from '@progress/kendo-react-layout';
import * as React from 'react';
import { HelpCenterMenu } from './HelpCenterMenu';
import { HelpCenterDocument } from './HelpCenterDocument';
import { GearSettingMenuItem } from '../../../Model/GearSettingMenuItem';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { useState } from 'react';
import {
  GEAR_HELP_CENTER_MENU_ITEM_TYPE,
  GearHelpCenterMenuItemType
} from '../../../global/enums/GearHelpCenterMenuItemType';

const postitions: TilePosition[] = [
  {
    col: 1,
    colSpan: 1
  },
  {
    col: 2,
    colSpan: 50
  }
];

export const HelpCenterManagement: React.FC = () => {
  const localization = useLocalization();
  const [selectedMenuItem, setSelectedMenuItem] = useState<GearHelpCenterMenuItemType>(
    GEAR_HELP_CENTER_MENU_ITEM_TYPE.USER_MANUAL
  );
  const menu: GearSettingMenuItem[] = [
    {
      text: IntlHelper.toLangStr(localization, 'custom.settings.helpCenter.userManual'),
      value: GEAR_HELP_CENTER_MENU_ITEM_TYPE.USER_MANUAL
    },
    {
      text: IntlHelper.toLangStr(localization, 'custom.settings.helpCenter.adminManual'),
      value: GEAR_HELP_CENTER_MENU_ITEM_TYPE.ADMIN_MANUAL
    },
    {
      text: IntlHelper.toLangStr(localization, 'custom.settings.helpCenter.FAQ'),
      value: GEAR_HELP_CENTER_MENU_ITEM_TYPE.FAQ
    },
    {
      text: IntlHelper.toLangStr(localization, 'custom.settings.helpCenter.reportIssue.title'),
      value: GEAR_HELP_CENTER_MENU_ITEM_TYPE.RAPORT_ISSUE
    }
  ];

  const tiles: TileLayoutItem[] = [
    {
      body: (
        <HelpCenterMenu
          menu={menu}
          value={selectedMenuItem}
          onSelectedMenuItemChanged={(value: GearHelpCenterMenuItemType) => setSelectedMenuItem(value)}
        />
      ),
      className: 'helpCenterTileLayoutItem',
      reorderable: false,
      resizable: false
    },
    {
      body: <HelpCenterDocument selectedMenuItem={selectedMenuItem} />,
      className: 'helpCenterTileLayoutItem',
      reorderable: false,
      resizable: false
    }
  ];
  return (
    <div className="helpCenter">
      <TileLayout columns={2} positions={postitions} items={tiles} />
    </div>
  );
};
