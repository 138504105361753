import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@progress/kendo-react-common';

type CustomGearIcon = 'backPageIcon' | 'removeIcon' | 'clearFilterIcon';

interface GearSvgIconProps extends SvgIconProps {
  onClick?: () => void;
  customIcon?: CustomGearIcon;
}

interface GearIconProps {
  viewBox: string;
  path: string;
}

const getIconsProps = (iconType: CustomGearIcon): GearIconProps => {
  let result: GearIconProps;
  switch (iconType) {
    case 'backPageIcon':
      result = {
        viewBox: '0 -960 960 960',
        path: 'M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z'
      };
      break;
    case 'removeIcon':
      result = {
        viewBox: '0 -960 960 960',
        path: 'M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z'
      };
      break;
    case 'clearFilterIcon':
      result = {
        viewBox: '0 0 24 24',
        path: 'M2.85363 2.14645C2.65837 1.95118 2.34179 1.95118 2.14652 2.14645C1.95126 2.34171 1.95126 2.65829 2.14652 2.85355L21.1465 21.8536C21.3418 22.0488 21.6584 22.0488 21.8536 21.8536C22.0489 21.6583 22.0489 21.3417 21.8536 21.1464L2.85363 2.14645ZM6.1808 3C5.90466 3 5.6808 3.22386 5.6808 3.5C5.6808 3.77614 5.90466 4 6.1808 4H19.4073L13.5732 10.7525C13.3927 10.9615 13.4158 11.2772 13.6247 11.4577C13.8337 11.6383 14.1494 11.6152 14.3299 11.4063L20.8784 3.82689C21.0063 3.67886 21.0361 3.46985 20.9547 3.29197C20.8734 3.11408 20.6957 3 20.5001 3H6.1808ZM11.0011 13.2058C11.0011 12.9297 10.7772 12.7058 10.5011 12.7058C10.2249 12.7058 10.0011 12.9297 10.0011 13.2058V18.6172C10.0011 18.7891 10.0894 18.9489 10.235 19.0405L13.2299 20.9233C13.384 21.0202 13.5785 21.0257 13.7378 20.9376C13.8971 20.8496 13.996 20.682 13.996 20.5V16.3325C13.996 16.0564 13.7721 15.8325 13.496 15.8325C13.2199 15.8325 12.996 16.0564 12.996 16.3325V19.5951L11.0011 18.3409V13.2058Z'
      };
      break;
    default:
      result = {
        viewBox: '',
        path: ''
      };
      break;
  }
  return result;
};

export const GearSvgIcon: React.FC<GearSvgIconProps> = props => {
  const iconProps = props.customIcon
    ? getIconsProps(props.customIcon)
    : {
        viewBox: props.viewBox || '',
        path: props.path || ''
      };
  return (
    <SvgIcon
      viewBox={iconProps.viewBox}
      svgClassName={props.svgClassName}
      svgStyle={props.svgStyle}
      className={props.className}
      onClick={props.onClick}
    >
      <path d={iconProps.path} />
    </SvgIcon>
  );
};
