import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { GRID_COL_INDEX_ATTRIBUTE, GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';
import * as React from 'react';
import { COLUMN_WIDTH_STEP, EMPTY_VALUE, MAX_COLUMN_WIDTH, MIN_COLUMN_WIDTH } from '../../../../config/consts';
import { NumericTextBox, NumericTextBoxChangeEvent, NumericTextBoxFocusEvent } from '@progress/kendo-react-inputs';
export interface EditableWidthCellProps extends GridCellProps {
  onItemChange: (event: GridItemChangeEvent) => void;
}

export const EditableWidthCell: React.FC<EditableWidthCellProps> = props => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const field = props.field || EMPTY_VALUE;
  const value = props.dataItem[field];

  const handleChange = (event: NumericTextBoxChangeEvent): void => {
    if (!props.onItemChange) {
      return;
    }
    let newValue: undefined | number;
    if (event.target.value === null || event.target.value === undefined) {
      newValue = undefined;
    } else if (event.target.value < MIN_COLUMN_WIDTH || event.target.value > MAX_COLUMN_WIDTH) {
      newValue = value;
    } else {
      newValue = event.target.value;
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const newEvent: any = {
      ...event,
      dataItem: props.dataItem,
      field: field,
      value: newValue
    };
    props.onItemChange(newEvent);
  };

  const handleFocus = (event: NumericTextBoxFocusEvent): void => {
    event.target.element?.select();
  };

  return (
    <td
      className={props.className}
      style={props.style}
      colSpan={props.colSpan}
      role={'gridcell'}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex
      }}
      {...navigationAttributes}
    >
      <NumericTextBox
        format="n0"
        step={COLUMN_WIDTH_STEP}
        min={MIN_COLUMN_WIDTH}
        max={MAX_COLUMN_WIDTH}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        valid={!isNaN(value)}
      />
    </td>
  );
};
