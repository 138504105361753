import * as React from 'react';
import TopBar from '../components/topBar/TopBar';
import Footer from '../components/Footer';
import GearPage from './GearPage';
import GearGrid from '../components/grid/GearGrid';

const MainPage: React.FC = () => {
  return (
    <GearPage>
      <TopBar />
      <GearGrid />
      <Footer />
    </GearPage>
  );
};

export default MainPage;
