import * as React from 'react';
import GearPage from './GearPage';
import { useUiAdminConfigsContext } from '../context/uiAdminConfigs/UiAdminConfigsContext';
import { GearMaintenancePageState } from '../Model/GearMaintenancePageState';
import TopBar from '../components/topBar/TopBar';
import Footer from '../components/Footer';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from '../global/enums/RoutePath';

const MaintenancePage: React.FC = () => {
  const { uiAdminConfigState } = useUiAdminConfigsContext();

  const [maintenancePageState, setMaintenancePageState] = React.useState<GearMaintenancePageState>();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (uiAdminConfigState.maintenancePage) {
      setMaintenancePageState(uiAdminConfigState.maintenancePage);
    } else {
      return;
    }
  }, [uiAdminConfigState]);

  React.useEffect(() => {
    const isMaintenancePath = matchPath(`${RoutePath.MAINTENANCE}`, pathname);
    if (!isMaintenancePath) {
      navigate(`/${RoutePath.MAINTENANCE}`);
    }
  }, []);

  return (
    <GearPage>
      <TopBar />
      {maintenancePageState?.enabled && (
        <div dangerouslySetInnerHTML={{ __html: maintenancePageState.pageContent || '' }} />
      )}
      <Footer />
    </GearPage>
  );
};

export default MaintenancePage;
