import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import ServicesContext from '../../global/services/ServicesContext';
import { mutateUiAdminConfigKeys } from './mutateKeyFactory';
import { uiAdminConfigsKeys } from './queryKeyFactory';
import { GearUiAdminConfigType } from '../../Model/uiAdminConfig/GearUiAdminConfigType';

export function useUpsertUiAdminConfigEntry(): UseMutationResult<
  void,
  unknown,
  {
    key: GearUiAdminConfigType;
    body: string;
  }
> {
  const servicesCtx = useContext(ServicesContext);
  const queryClient = useQueryClient();

  const upsertUiAdminConfigEntry = (data: { key: GearUiAdminConfigType; body: string }) => {
    if (servicesCtx?.uiAdminConfigsService) {
      return servicesCtx.uiAdminConfigsService.upsertUiAdminConfigEntry(data.key, data.body);
    }
    throw new Error('Services context not initialized');
  };

  return useMutation({
    mutationKey: mutateUiAdminConfigKeys.upsertUiAdminConfigEntry,
    mutationFn: upsertUiAdminConfigEntry,
    onSuccess: async (
      _,
      variables: {
        key: GearUiAdminConfigType;
        body: string;
      }
    ) => {
      await queryClient.invalidateQueries(uiAdminConfigsKeys.uiAdminConfigByKey(variables.key));
    }
  });
}
