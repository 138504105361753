import { GearSearchExportRequest } from '../../Model/GearSearchExportRequest';
import { GearSearchQueryRequest } from '../../Model/GearSearchQueryRequest';
import { GearView } from '../../Model/GearView';
import { ExecuteEndpoint } from '../../components/common/BaseApiService';

export class SearchService {
  public async getSearchResults(querySearchRequest: GearSearchQueryRequest): Promise<GearView> {
    return ExecuteEndpoint(`search`, 'POST', { json: querySearchRequest });
  }

  public async triggerExportToCsv(querySearchExportRequest: GearSearchExportRequest): Promise<void> {
    return ExecuteEndpoint(`search/export`, 'POST', { json: querySearchExportRequest });
  }
}
