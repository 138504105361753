import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export const dayjsSetup = (): void => {
  dayjs.extend(utc);
};

export const getGearDateFilterFormat = (date: Date) => {
  return dayjs.default(date).hour(12).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
};

export const getGearDateTimeFilterFormat = (date: Date) => {
  return dayjs.utc(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
};
