import * as React from 'react';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { FormSwitcher } from '../../common/form/fields/FormSwitcher';
import { useUiAdminConfigsContext } from '../../../context/uiAdminConfigs/UiAdminConfigsContext';
import GearLoader from '../../common/GearLoader';
import { LoadingGridPanel } from '../../grid/LoadingGridPanel';
import { GEAR_UI_ADMIN_CONFIG_TYPE } from '../../../Model/uiAdminConfig/GearUiAdminConfigType';
import { GearMaintenancePageState } from '../../../Model/GearMaintenancePageState';
import { FormRichTextEditor } from '../../common/form/fields/FormRichTextEditor';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Editor, EditorUtils } from '@progress/kendo-react-editor';
import TopBar from '../../topBar/TopBar';
import Footer from '../../Footer';
import { maxLengthRichTextEditor } from '../../common/form/validators/FormValidator';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { GEAR_CONFIRM_DIALOG_STATE, GearConfirmDialogState } from '../../../Model/GearConfirmDialogState';

interface MaintenancePageDialogState {
  visible: boolean;
  values?: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [name: string]: any;
  };
}

export const MaintenancePageManagement: React.FC = () => {
  const [initialValues, setInitValues] = React.useState<Partial<GearMaintenancePageState>>();
  const [dialogState, setDialogState] = React.useState<MaintenancePageDialogState>({ visible: false });
  const localization = useLocalization();

  const {
    isLoading,
    uiAdminConfigState,
    upsertUiAdminConfigEntry,
    uiAdminConfigPreviewState,
    setUiAdminConfigPreviewState,
    refreshUiAdminConfigState
  } = useUiAdminConfigsContext();

  const editor = React.createRef<Editor>();

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (uiAdminConfigState) {
      const values: GearMaintenancePageState = uiAdminConfigState.maintenancePage as GearMaintenancePageState;
      if (editor.current) {
        const view = editor.current.view;
        if (view && values.pageContent) {
          EditorUtils.setHtml(view, values.pageContent);
        }
      }
      setInitValues(values);
    } else {
      setInitValues({});
    }
  }, [uiAdminConfigState]);

  const upsertMaintenancePage = (values: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [name: string]: any;
  }) => {
    const body = JSON.stringify(values);
    upsertUiAdminConfigEntry(GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE, body);

    if (setUiAdminConfigPreviewState && uiAdminConfigPreviewState?.maintenancePage) {
      setUiAdminConfigPreviewState({
        ...uiAdminConfigPreviewState,
        [GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE]: undefined
      });
    }

    refreshUiAdminConfigState(GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE);
  };

  const handleSubmit = (values: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [name: string]: any;
  }) => {
    if (values.enabled) {
      setDialogState({ visible: true, values });
    } else {
      upsertMaintenancePage(values);
    }
  };

  const handlePreview = (formRenderProps: FormRenderProps) => {
    if (setUiAdminConfigPreviewState) {
      const maintenancePagePreviewState: GearMaintenancePageState = {
        enabled: true,
        pageContent: formRenderProps.valueGetter('pageContent')
      };
      setUiAdminConfigPreviewState({
        ...uiAdminConfigPreviewState,
        [GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE]: maintenancePagePreviewState
      });
    }
  };

  const discardFormChanges = () => {
    if (setUiAdminConfigPreviewState) {
      setUiAdminConfigPreviewState({
        ...uiAdminConfigPreviewState,
        [GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE]: undefined
      });
    }
  };

  const handleDialogConfirm = (confirmed: GearConfirmDialogState) => {
    if (confirmed === GEAR_CONFIRM_DIALOG_STATE.YES && dialogState.values) {
      upsertMaintenancePage(dialogState.values);
    }
    setDialogState({ visible: false });
  };

  if (!initialValues) {
    return <GearLoader />;
  }

  return (
    <div>
      {!initialValues && <LoadingGridPanel />}
      <div className="gearForm">
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement>
                <Field
                  name={'enabled'}
                  component={FormSwitcher}
                  label={IntlHelper.toLangStr(localization, 'custom.common.enable')}
                />
                <h3>{IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.maintenancePage.pageContent')}</h3>
                <Field
                  name={'pageContent'}
                  component={FormRichTextEditor}
                  validator={(value: string) => maxLengthRichTextEditor(value, localization)}
                />
                <div className={'gearFormButtons'}>
                  <Button
                    type={'button'}
                    themeColor={'secondary'}
                    fillMode="solid"
                    onClick={() => handlePreview(formRenderProps)}
                  >
                    {IntlHelper.toLangStr(localization, 'custom.common.preview')}
                  </Button>
                  <Button themeColor="dark" disabled={!formRenderProps.modified || !formRenderProps.valid}>
                    {IntlHelper.toLangStr(localization, 'custom.common.save')}
                  </Button>
                </div>
              </FormElement>
            );
          }}
        />
      </div>
      {uiAdminConfigPreviewState.maintenancePage?.enabled && (
        <Dialog
          onClose={discardFormChanges}
          title={
            IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.maintenancePage.title') +
            ' - ' +
            IntlHelper.toLangStr(localization, 'custom.common.preview')
          }
          closeIcon={true}
          width={'100%'}
          height={'100%'}
          contentStyle={{ padding: 0 }}
        >
          <div className="app previewMaintanancePage" style={{}}>
            <TopBar maintainancePagePreview={true} />
            <div
              dangerouslySetInnerHTML={{
                __html: (uiAdminConfigPreviewState.maintenancePage as GearMaintenancePageState)?.pageContent || ''
              }}
            />
            <Footer />
          </div>
        </Dialog>
      )}
      {dialogState.visible && (
        <ConfirmDialog
          message={IntlHelper.toLangStr(
            localization,
            'custom.settings.adminCenter.maintenancePage.confirmationMessage'
          )}
          onConfirm={handleDialogConfirm}
        />
      )}
    </div>
  );
};
