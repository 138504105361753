import * as React from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <GridLayout
        gap={{
          rows: 1,
          cols: 2
        }}
        align={{ vertical: 'middle' }}
        className="footerGrid"
      >
        <GridLayoutItem row={1} col={1}>
          <a href="https://www.volvogroup.com/" target="_blank" rel="noreferrer">
            volvogroup.com
          </a>
        </GridLayoutItem>
        <GridLayoutItem row={1} col={2} style={{ textAlign: 'right' }}>
          © Copyright AB Volvo 2024
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

export default Footer;
