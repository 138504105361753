import { TileLayout, TileLayoutItem, TilePosition } from '@progress/kendo-react-layout';
import * as React from 'react';
import { DefaultView } from './DefaultView';
import { PrivateView } from './privateView/PrivateView';
import { useLocalization } from '@progress/kendo-react-intl';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { PrivateViewHeader } from './privateView/privateViewHeader/PrivateViewHeader';
import { FieldsProvider } from '../../../context/fields/FieldsProvider';
import { ViewsManagementHeaderWrapper } from './ViewsManagementHeader';

const postitions: TilePosition[] = [
  {
    col: 1
  },
  {
    col: 2
  }
];

export const ViewsManagement: React.FC = () => {
  const localization = useLocalization();
  const tiles: TileLayoutItem[] = [
    {
      header: (
        <ViewsManagementHeaderWrapper>
          {IntlHelper.toLangStr(localization, 'custom.settings.views.default.title')}
        </ViewsManagementHeaderWrapper>
      ),
      body: <DefaultView />,
      reorderable: false,
      resizable: false
    },
    {
      header: (
        <ViewsManagementHeaderWrapper>
          <PrivateViewHeader />
        </ViewsManagementHeaderWrapper>
      ),
      body: (
        <FieldsProvider>
          <PrivateView />
        </FieldsProvider>
      ),
      reorderable: false,
      resizable: false
    }
  ];
  return (
    <div className="viewsManagement">
      <TileLayout columns={2} positions={postitions} items={tiles} />
    </div>
  );
};
