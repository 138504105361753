import * as React from 'react';
import { UiAdminConfigsContext } from './UiAdminConfigsContext';
import { useGetUiAdminConfigEntry } from './useGetUiAdminConfigEntry';
import { useNotificationContext } from '../../global/hooks/useNotificationContext';
import { useUpsertUiAdminConfigEntry } from './useUpsertUiAdminConfigEntry';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { useGetUiAdminConfigWritePermissions } from './useGetUiAdminConfigWritePermissions';
import { GEAR_UI_ADMIN_CONFIG_TYPE, GearUiAdminConfigType } from '../../Model/uiAdminConfig/GearUiAdminConfigType';
import { GearUiAdminConfigState } from '../../Model/uiAdminConfig/GearUiAdminConfigState';
import { useLocation } from 'react-router-dom';

interface UiAdminConfigsProviderProps {
  children?: React.ReactNode;
}

export const UiAdminConfigsProvider: React.FC<UiAdminConfigsProviderProps> = ({ children }) => {
  const [configKey, setConfigKey] = React.useState<GearUiAdminConfigType>();
  const [configWritePermissionsKeys, setConfigWritePermissionsKeys] = React.useState<GearUiAdminConfigType[]>();
  const [uiAdminConfigState, setUiAdminConfigState] = React.useState<GearUiAdminConfigState>({});
  const { data: uiAdminConfigEntry, isLoading: isLoadingGetUiAdminConfigEntry } = useGetUiAdminConfigEntry(configKey);
  const { data: uiAdminConfigWritePermissions, isLoading: isLoadingUiAdminConfigWritePermissions } =
    useGetUiAdminConfigWritePermissions(configWritePermissionsKeys);
  const { mutate: mutateUpsertUiAdminConfig, isLoading: isLoadingMutateUpsertUiConfigEntry } =
    useUpsertUiAdminConfigEntry();
  const { showNotification } = useNotificationContext();
  const localization = useLocalization();
  const { pathname } = useLocation();

  const handleUpsertUiAdminConfigEntry = (key: GearUiAdminConfigType, body: string): void => {
    if (key !== configKey) {
      setConfigKey(key);
    }
    mutateUpsertUiAdminConfig(
      { key, body },
      {
        onSuccess: () => {
          showNotification({
            type: 'success',
            message: IntlHelper.toLangStr(
              localization,
              key === GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR
                ? 'custom.notifications.messageBarSaved'
                : 'custom.notifications.maintenancePageSaved'
            )
          });
        }
      }
    );
  };

  const [uiAdminConfigPreviewState, setUiAdminConfigPreviewState] = React.useState<GearUiAdminConfigState>({
    [GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR]: undefined,
    [GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE]: undefined
  });

  React.useEffect(() => {
    // GET initial UiAdminConfigState
    if (!(GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE in uiAdminConfigState)) {
      setConfigKey(GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE);
    } else if (!(GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR in uiAdminConfigState)) {
      setConfigKey(GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR);
    }
  }, [uiAdminConfigState]);

  React.useEffect(() => {
    if (!configKey || !setUiAdminConfigState) {
      return;
    }
    setUiAdminConfigState({
      ...uiAdminConfigState,
      [configKey]: uiAdminConfigEntry ? JSON.parse(uiAdminConfigEntry) : {}
    });
  }, [uiAdminConfigEntry]);

  React.useEffect(() => {
    // reset preview State on route change
    setUiAdminConfigPreviewState({
      [GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR]: undefined,
      [GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE]: undefined
    });
  }, [pathname]);

  const refreshUiAdminConfigState = (newConfigKey: GearUiAdminConfigType) => {
    setConfigKey(newConfigKey);
  };

  return (
    <UiAdminConfigsContext.Provider
      value={{
        isLoading:
          isLoadingGetUiAdminConfigEntry ||
          isLoadingMutateUpsertUiConfigEntry ||
          isLoadingUiAdminConfigWritePermissions,
        upsertUiAdminConfigEntry: handleUpsertUiAdminConfigEntry,
        setConfigWritePermissionsKeys,
        uiAdminConfigWritePermissions,
        uiAdminConfigPreviewState,
        setUiAdminConfigPreviewState,
        uiAdminConfigState,
        refreshUiAdminConfigState
      }}
    >
      {children}
    </UiAdminConfigsContext.Provider>
  );
};
