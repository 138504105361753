import * as React from 'react';

interface ViewsManagementHeaderWrapperProps {
  children: React.ReactNode;
}

export const ViewsManagementHeaderWrapper: React.FC<ViewsManagementHeaderWrapperProps> = ({ children }) => {
  return (
    <div className="viewsManagementHeaderWrapper">
      <h5 className="k-card-title">{children}</h5>
    </div>
  );
};
