import * as React from 'react';
import SearchResultsCount from './SearchResultsCount';
import FilterActions from './FilterActions';
import ClearFiltersButton from './ClearFiltersButton';
import { GlobalFilters } from './globalFilters/GlobalFilters';
import IndexDropdown from './IndexDropdown';

export const GearToolbar: React.FC = () => {
  return (
    <>
      <div className="gearToolbar">
        <SearchResultsCount />
        <GlobalFilters />
        <div className="actionButtonsContainer">
          <IndexDropdown />
          <ClearFiltersButton />
          <FilterActions />
        </div>
      </div>
    </>
  );
};
