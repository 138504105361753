import * as React from 'react';
import { useState } from 'react';
import { NotificationContext } from './NotificationContext';
import { GearNotification, GearNotificationProps } from '../../components/common/GearNotification';

interface NotificationContextProviderProps {
  children: React.ReactNode;
}

export const NotificationContextProvider: React.FC<NotificationContextProviderProps> = ({ children }) => {
  const [notificationProps, setNotificationProps] = useState<GearNotificationProps | null>(null);

  const showNotification = (props: Omit<GearNotificationProps, 'show'>): void => {
    setNotificationProps({ ...props, show: true });
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {notificationProps && <GearNotification {...notificationProps} onClose={() => setNotificationProps(null)} />}
    </NotificationContext.Provider>
  );
};
