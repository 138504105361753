import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import ServicesContext from '../../global/services/ServicesContext';
import { GearSearchExportRequest } from '../../Model/GearSearchExportRequest';
import { mutateSearchKeys } from './mutateKeyFactory';

export function useTriggerExportToCsv(): UseMutationResult<
  void,
  unknown,
  {
    dto: GearSearchExportRequest;
  }
> {
  const servicesCtx = useContext(ServicesContext);

  const triggerExportToCsv = (data: { dto: GearSearchExportRequest }) => {
    if (servicesCtx?.searchService) {
      return servicesCtx.searchService.triggerExportToCsv(data.dto);
    }
    throw new Error('Services context not initialized');
  };

  return useMutation({
    mutationKey: mutateSearchKeys.triggerExportToCsv,
    mutationFn: triggerExportToCsv
  });
}
